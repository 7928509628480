import React from 'react';

const landing = (props: any) =>
(
  <aside className="landing">
    <header className="landing-header">
    </header>
    <main className="landing-body">
      <img className="gamacea-logo" src="https://www.tabletopgamersalliance.com/i/gamacea/2024/gamacea2024-banner-web.png" alt="" />
      <div className="gamacea-index-left">
        <br /><br />
      </div>
    </main>
    <footer className="landing-footer">
    </footer>
  </aside>
);

export default landing;
