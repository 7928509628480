import React from "react";
import Profile from '../../user/profile';

const ProfilePage = () =>
{
    return(
        <React.Fragment>
            <Profile></Profile>
        </React.Fragment>
    );
};
export default ProfilePage;