import React from 'react';
import {SubMenu, MenuItem} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import './QuarterlyYear.css';
import {Link} from "react-router-dom";

interface QuarterlyYearProps
{
  year: string;
  visible: boolean[];
}

export default function QuarterlyYearMenu(props: QuarterlyYearProps) {

  return(
    <SubMenu label={props.year}>
      {props.visible[0] && <Link className="QuarterlyMenu" to={"/"+props.year+"/Q1"}><MenuItem className="QuarterlyMenu">Q1</MenuItem></Link>}
      {props.visible[1] && <Link className="QuarterlyMenu" to={"/"+props.year+"/Q2"}><MenuItem className="QuarterlyMenu">Q2</MenuItem></Link>}
      {props.visible[2] && <Link className="QuarterlyMenu" to={"/"+props.year+"/Q3"}><MenuItem className="QuarterlyMenu">Q3</MenuItem></Link>}
      {props.visible[3] && <Link className="QuarterlyMenu" to={"/"+props.year+"/Q4"}><MenuItem className="QuarterlyMenu">Q4</MenuItem></Link>}
    </SubMenu>
  );
}
