import { EventInfo } from "../types/eventModel"
import moment from 'moment';

export function fetchEvents(authToken:string|undefined, onLoading:(isLoading:boolean)=>void, onSuccess:(events:EventInfo[])=>void, onError:(err:Error)=>void){
  onLoading(true)
  fetch(
    process.env.REACT_APP_REFLEBULA + '/a1/tga/events',
    {
      headers: authToken ? 
        { 'Authorization': `Bearer ${authToken}` } : 
        { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
  ).then((response) => {
      if (!response.ok) {
          throw response
      } else {
          return response.json()
      }
  }).then(
    r=>{
      onSuccess(r.data)
    }
  )
  .catch(e=>{onError(e)})
  .finally(()=>onLoading(false))
}

export function localToGMT(date: string|Date, time: string|Date, timezone: string): {date: string, time: string, dateTime: Date} {
  const dateInput = (typeof date === `string`) ? (moment(date,`YYYY-MM-DD`).toDate()) : (date);
  const timeInput = (typeof time === `string`) ? (moment(time,`HH:mm`).toDate()) : (time);
  dateInput.setHours(timeInput.getHours());
  dateInput.setMinutes(timeInput.getMinutes());
  const dateLocal = moment.tz(dateInput,timezone);
  const dateGMT = dateLocal.clone().tz('GMT');
  return {
    date: dateGMT.format(`YYYY-MM-DD`),
    time: dateGMT.format(`HH:mm`),
    dateTime: dateGMT.toDate()
  }
}

export function GMTToLocal(date: string|Date, time: string|Date, timezone: string): {date: string, time: string, dateTime: Date} {
  const dateInput = (typeof date === `string`) ? (moment(date,`YYYY-MM-DD`).toDate()) : (date);
  const timeInput = (typeof time === `string`) ? (moment(time,`HH:mm`).toDate()) : (time);
  dateInput.setHours(timeInput.getHours());
  dateInput.setMinutes(timeInput.getMinutes())
  const dateGMT = moment.tz(dateInput,`GMT`);
  const dateLocal = dateGMT.clone().tz(timezone);
  return {
    date: dateLocal.format(`YYYY-MM-DD`),
    time: dateLocal.format(`HH:mm`),
    dateTime: dateLocal.toDate()
  }
}

export function GMTToLocalIso(date: string|Date, timezone: string): {date: string, time: string, dateTime: Date} {
  moment.tz.setDefault('GMT');
  const dateInput = (typeof date === `string`) ? (moment(date,`YYYY-MM-DDTHH:mm:ss`).tz(`GMT`).toDate()) : (date);
  const dateGMT = moment.tz(dateInput,`GMT`);
  const dateLocal = dateGMT.clone().tz(timezone);
  console.log(dateInput,dateGMT,dateLocal);
  return {
    date: dateLocal.format(`YYYY-MM-DD`),
    time: dateLocal.format(`HH:mm`),
    dateTime: dateLocal.toDate()
  }
}

// Takes the eventSeries properties eventStartTime and eventEndTime and returns the duration of an event in minutes
export function getDuration(start: Date, end: Date): Number {
  const startValue = (typeof start === 'string') ? moment(start,`HH:mm`).toDate().valueOf() : start.valueOf();
  const endValue = (typeof end === 'string') ? moment(end,`HH:mm`).toDate().valueOf() : end.valueOf();
  
  const diff = (endValue - startValue) / 60000;
  const duration = Math.floor(diff < 0 ? 1440 + diff : diff);

  return duration
}
