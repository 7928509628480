import "react-big-calendar/lib/css/react-big-calendar.css";
import "./EventCalendar.css";
import DateTimePicker from 'react-datetime-picker';
import React from 'react';
import { CalEvent } from './EventCalendar';
import { useNavigate } from "react-router-dom";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import "./EventCreator.css";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece]

export interface EventCreationDetails {
    start: Value,
    end: Value,
    name: string,
    bgg_title_link: string,
    info: string,
    room: string,
    table: number,
    slots: number,
    owner_player: boolean,
    owner: number,
    event_id: number
}

interface EventCreatorProps {
    userId: number,
    token: string,
    defaults?: CalEvent,
    editEventId?: number,
    evtId: number
}

const EventCreator = (props: EventCreatorProps) => {

    const [memoEditEventId, setMemoEditEventId] = React.useState<number | undefined>(undefined);
    const [startTime, setStartTime] = React.useState<Value>(new Date(2024, 7, 16, 10, 0, 0));
    const [stopTime, setStopTime] = React.useState<Value>(new Date(2024, 7, 16, 10, 0, 0));
    const [eventName, setEventName] = React.useState<string>("");
    const [bggPage, setBggPage] = React.useState<string>("");
    const [eventDesc, setEventDesc] = React.useState<string>("");
    const [roomDesc, setRoomDesc] = React.useState<string>("Ballroom");
    const [tableNum, setTableNum] = React.useState<string>("");
    const [slotMax, setSlotMax] = React.useState<string>("");
    const [autoRSVP, setAutoRSVP] = React.useState<boolean>(false);
    const [statusMsg, setStatusMsg] = React.useState<string>("");
    const [submitEnable, setSubmitEnable] = React.useState<boolean>(true);
    const [tableVerify, setTableVerify] = React.useState<string>("");
    const [startRedirect, setStartRedirect] = React.useState<boolean>(false);

    const navigate = useNavigate()

    React.useEffect(() => {
        setStartTime(props.defaults ? props.defaults.start : new Date(2024, 7, 16, 10, 0, 0))
        setStopTime(props.defaults ? props.defaults.end : new Date(2024, 7, 16, 10, 0, 0))
        setEventName(props.defaults ? props.defaults.title : "")
        setBggPage((props.defaults && props.defaults.bgg_title_link) ? props.defaults.bgg_title_link : "")
        setEventDesc(props.defaults ? props.defaults.info : "")
        setTableNum(props.defaults ? props.defaults.table.toString() : "")
        setSlotMax(props.defaults ? props.defaults.slots_available.toString() : "")
        setMemoEditEventId(props.editEventId);
    }, [props.defaults, props.editEventId])

    const updateSession = () => {
        const object = {
            start_time: startTime,
            end_time: stopTime,
            name: eventName,
            bgg_title_link: bggPage,
            info: eventDesc,
            room: roomDesc,
            table: parseInt(tableNum),
            slots: parseInt(slotMax),
            owner_player: autoRSVP,
            owner: props.userId,
            event_id: props.evtId,
            id: memoEditEventId
        }
        console.log(object);
        const requestOptions = {
            body: JSON.stringify(object),
            method: 'POST',
            headers: {
                'Content-Type': `application/json`,
                "Access-Control-Allow-Origin": "*",
                'Authorization': `Bearer ${props.token}`
            }
        }
        return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/gsessions/editgsesson', requestOptions)
            .then(res => res.json())
            .then((result) => {
                if (result.status === 'success') {
                    setStatusMsg("Session Saved! Please Refresh Calendar.")
                    setSubmitEnable(false);
                }
                else {
                    console.log(result)
                    setStatusMsg("An Error has occured. " + result.reason);
                }
            })
    }
    const createSession = () => {
        const object = {
            start_time: startTime,
            end_time: stopTime,
            name: eventName,
            bgg_title_link: bggPage,
            info: eventDesc,
            room: roomDesc,
            table: parseInt(tableNum),
            slots: parseInt(slotMax),
            owner_player: autoRSVP,
            owner: props.userId,
            event_id: props.evtId
        }
        console.log(object);
        const requestOptions = {
            body: JSON.stringify(object),
            method: 'POST',
            headers: {
                'Content-Type': `application/json`,
                "Access-Control-Allow-Origin": "*",
                'Authorization': `Bearer ${props.token}`
            }
        }
        return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/gsessions/creategsession', requestOptions)
            .then(res => res.json())
            .then((result) => {
                if (result.status === 'success') {
                    setStatusMsg("Session Saved! Calendar is now refreshing...");
                    setSubmitEnable(false);
                    setStartRedirect(true);
                }
                else {
                    console.log(result)
                    setStatusMsg("An Error has occured. " + result.reason);
                }
            })

    }

    React.useEffect(() => {
        if(startRedirect) {
            setTimeout(() => {
                window.location.reload();
              }, 3000)
        }
      }, [startRedirect])

    const submitEnabledandValidated = submitEnable && (tableVerify === "") && (eventName !== "") && (roomDesc !== "") && (slotMax !== "")

    return (<div>
        <b>Creator View</b>
        {memoEditEventId && <div><br></br> <b>Editing Selected Event!!</b></div>}
        <p>Session Name</p>
        <input name="EventName"
            className="smallInput"
            placeholder="Game Name"
            value={eventName}
            onChange={(val) => setEventName(val.target.value)} />
        <p>BGG Page</p>
        <textarea name="BGGPage"
            className="smallTextField"
            value={bggPage}
            placeholder="https://www.boardgamegeek.com//....."
            onChange={(e) => setBggPage(e.target.value)} />
        <p>Description</p>
        <textarea name="Description"
            className="largeTextField"
            placeholder="What people need to know"
            value={eventDesc}
            onChange={(e) => setEventDesc(e.target.value)} />
        <p>Table</p>
        <input name="Table"
            className="smallInput"
            value={tableNum}
            placeholder="Check Event Map for table #s"
            pattern="[0-9]"
            onChange={(e) => {
                setTableNum(e.target.value);
                const res = parseInt(e.target.value, 10);
                if (isNaN(res) || res < 1 || res > 17) {
                    setTableVerify("Table must be a number between 1 and 17");
                } else {
                    setTableNum(e.target.value);
                    setTableVerify("");
                }
            }} />
        <b>{tableVerify}</b>
        <p>Number of Slots</p>
        <input name="Slots"
            className="smallInput"
            placeholder="Maximum number of participants"
            value={slotMax}
            onChange={(e) => setSlotMax(e.target.value)} />
        <p>Start Time</p>
        <DateTimePicker name="StartTime"
            defaultValue={startTime}
            value={startTime}
            onChange={(val) => setStartTime(val)} />
        <p>Stop Time</p>
        <DateTimePicker name="StopTime"
            defaultValue={stopTime}
            value={stopTime}
            onChange={(val) => setStopTime(val)} />
        <br></br>
        <br></br>
        {!memoEditEventId && <label>
            <input type="checkbox" name="AutoRSVP"
                checked={autoRSVP}
                onChange={() => setAutoRSVP(!autoRSVP)} /> Also RSVP yourself to this event
        </label>}
        <br></br>
        <br></br>
        {submitEnabledandValidated && !memoEditEventId && <button
            onClick={() => createSession()}
        >Create Session</button>}
        {submitEnabledandValidated && memoEditEventId && <button
            onClick={() => updateSession()}
        >Update Session</button>}
        <br></br>
        <b>{statusMsg}</b>
    </div>)
}

export default EventCreator;