import React, { useEffect } from "react";

interface StaticRouteprops
{
  dest: string
}

function Static1(props: StaticRouteprops) {

  useEffect(() => {
    window.location.replace(props.dest);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <h2>loading archive...</h2>
    </div>
  );
}

export default Static1;
