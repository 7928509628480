import React, { useEffect, useState} from 'react'
import {
  useForm, FieldValues, SubmitHandler, Controller
} from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { SingleValue } from 'react-select';
import { ErrorMessage } from "@hookform/error-message"
import { debounce ,getAddressData } from '../utils';
import { CreateVenueInput, VenueDetails } from '../venueModels';
import './VenueForm.css'
import 'leaflet/dist/leaflet.css';
import '../index.css'


interface venueFormProps {
  containerClassname?:string,
  venue: VenueDetails | CreateVenueInput,
  onSubmit: (input:CreateVenueInput|VenueDetails)=>void,
  onVenueEntryChange?:Function // Allows passing the form info to a sibling map component
}

export const VenueForm: React.FC<venueFormProps> = (props)=>{
  const { containerClassname, onVenueEntryChange, venue, onSubmit } = props
  const [addressInputValue, setAddressInputValue] = useState<string | undefined>('')
  const {
    formState: { errors }, getValues, register, setValue : setFormValue, handleSubmit, control,
  } = useForm<FieldValues>(
    {
      defaultValues: {
        name:'',
        introduction:'',
        street_address:'',
        detailed_directions:'',
        private:false
      },
      mode:'onBlur'
    },
  );


  useEffect(()=>{
    setFormValue('name', venue.name)
    setFormValue('introduction', venue.introduction)
    setFormValue('street_address', venue.street_address)
    setFormValue('detailed_directions', venue.detailed_directions)
    setFormValue('private', venue.private)

    /* 
      In case the street address is not complete (either because it was set prior to
      OSM integration or trimmed to accomdate db field size), we need to query OSM and 
      replace the address from DB with complete OSM address, since we extract country data
      and postcode data on the assumption they are they last two comma-delimited fields of
      the OSM street address.)
    */
    if(venue.street_address){
      getAddressData(venue.street_address).then(d=>{
        if(Array.isArray(d)){
          setFormValue('street_address', {label:d[0].display_name, value:d[0].display_name})
          setAddressInputValue(d[0].display_name)
          if(d.length > 1)console.warn('ambiguous address')
        }
      })
      setAddressInputValue(venue.street_address)
    }
  },[venue, setFormValue])

  const parseAddress = (add:string):{agg_address:string, street_address: string, postcode:string, country:string}=>{
    if(typeof(add) === 'string'){
      const addressComponents = add.split(',')
      return ({
        // A venue has fields related to address:
        //  agg_address: which is the complete location used by OSM, includes city, zip, etc.
        //  address: which is typically just the street address without city, etc.
        agg_address:addressComponents.join(),
        street_address:addressComponents.slice(0,3).join(),
        country:addressComponents[addressComponents.length-1],
        postcode:addressComponents[addressComponents.length-2]
      })
    }else{
      throw(`${JSON.stringify(add)} is not a string`)
    }
  }
  
  return (
    <form 
      onSubmit={handleSubmit((values)=>onSubmit(Object.assign(venue, values, parseAddress(values.street_address.value))))} 
      className={containerClassname || 'venue-form'}
    >
      <input
        className='venue-form-item'
        type="text"
        placeholder="Venue Name"
        {...register("name", {
          required: 'Venue name is required'
        })}
      />
      <ErrorMessage
        errors={errors}
        name="name"
        render={({ message }) => <p className='helper-text'>{message}</p>}
      />
      <Controller
        rules={{required:"A valid street address is required"}}
        name='street_address'
        control={control}
        render={(field)=>(
          <AsyncSelect
              classNames={{

              }}
              {...field}
              inputId = "venue-form-address-select-input"
              styles={{
                input:(baseStyle, state)=>({
                  ...baseStyle
                }),
                control:(baseStyle, state)=>({
                  ...baseStyle
                }),
                container:(baseStyle, state)=>({
                  ...baseStyle
                })
              }} 
              loadOptions={
              debounce((input:string, cb:Function)=>{
                getAddressData(input).then(d=>{
                  if(Array.isArray(d)){
                    cb(d.map(add=>({label:add.display_name, value:add}))); 
                  }
                })
              },400)
            }

            inputValue={addressInputValue}
            onInputChange={(value, {action}) => {
              /* only set the input when the action that caused the
               change equals to "input-change" and ignore the other
               ones like: "set-value", "input-blur", and "menu-close"
               this prevents the input value from being cleared on select
               */
              if (action === "input-change")  {
                setAddressInputValue(value);
              }
            }}
            placeholder="Street Address"
            blurInputOnSelect={false} //set by default, but to be sure
            closeMenuOnSelect={false}
            onChange={(untypedOpt, action)=>{
              const opt = untypedOpt as SingleValue<{label:string, value:any}>
              setAddressInputValue(opt?.value.display_name)
              setFormValue('street_address', {label:opt?.value.display_name, value:opt?.value.display_name})
              let newVenue = Object.assign(venue,getValues(), {street_address:getValues()['street_address']['label']})
              onVenueEntryChange && onVenueEntryChange(newVenue)
            }}
            onFocus = {()=>((document?.querySelector && document.querySelector("#venue-form-address-select-input")! as HTMLInputElement).select())}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name="street_address"
        render={({ message }) => <p className='helper-text'>{message}</p>}
      />
      <div className='venue-form-item' style={{paddingTop:0}}>
        <input 
          type='checkbox' 
          {...register("private",{
          })}
          >
        </input>
        <label htmlFor='private'>Private Venue (Hide address)</label>
      </div>
      <textarea
        style={{flexGrow:1}}
        className='venue-form-item'
        placeholder='Introduction and Description'
        {...register("introduction", {
          required: false
        })}
      />
      <textarea
        style={{flexGrow:1}}
        className='venue-form-item'
        placeholder='What should people do when they arrive?'
        {...register("detailed_directions", {
          required: false
        })}
      />
      <button
        value='Submit Venue'
        className='venue-editor-button'
        type="submit"
      >
        Submit Venue
      </button>
    </form>
  )
}

export default VenueForm