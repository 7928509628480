import React from 'react';

const venueLodging = (props: any) =>
(
  <div>
    <b>Courtyard Marriott Natick Room Block</b>
    <p>We have a small room block for gamers visiting from afar in order for you to take the most advantage of the 32 straight hours of gaming! These rooms area provided by the hotel at a discounted base rate of $109 (additional guest room taxes apply) for the nights of April 26th and April 27th.</p>
    <a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1543354357348&key=GRP&app=resvlink">Reserve your hotel room using this special link now! Booking deadline for this special rate is 4PM on March 31st!</a>
  </div>
);

export default venueLodging;
