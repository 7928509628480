import React, { useEffect, useState, SyntheticEvent } from 'react'
import { useWindowDimensions } from '../../../utils'
import { fetchEvents, localToGMT, GMTToLocalIso } from './utils'
import './styles/index.css'; // these styles probably need to move elsewhere
import { EventCreationDetails, EventInfo, EventSeriesProps } from './types/eventModel'
import { ClickableCalendar } from './components/ClickableCalendar'
import { EventForm } from './components/EventForm'
import moment from 'moment'

interface EventCalendarProps {
  loggedInUser?: number,
  authToken?: string
}

export const EventCalendar:React.FC<EventCalendarProps> = (props)=>{
  const { loggedInUser, authToken } = props
  const { windowHeight, windowWidth } = useWindowDimensions()
  const [calendarEvents, setCalendarEvents] = useState<EventInfo[]>([])
  const [createEventDialogOpen, setCreateEventDialogOpen] = useState(false)
  const [eventsLoading, setEventsLoading] = useState(false)
  const [eventsLoadingFailed, setEventsLoadingFailed] = useState(false)
  const [userCanCreate, setUserCanCreate] = useState(false);

  //TODO: Make this local time
  const timeZone = 'America/New_York';
  moment.tz.setDefault('America/New_York');

  useEffect(()=>{
    if(calendarEvents.length === 0){
      fetchEvents(authToken, setEventsLoading, handleEventsLoaded, (err)=>console.error(err))
    }
  },[authToken])
  
  const handleEventsLoaded = (events:EventInfo[])=>setCalendarEvents(events.map(e=>{
    //Convert Time Zone from GMT to Local
    const startTime = GMTToLocalIso(e.startTime, `America/New_York`);
    const endTime = GMTToLocalIso(e.endTime, `America/New_York`);
    return {start:startTime.dateTime, end:endTime.dateTime, title:e.name, ...e }
  }))

  const handleEventDelete = (requestBody:EventInfo|EventSeriesProps)=>{
    if(Object.keys(requestBody).includes("startTime")){
      //delete single instance
      const eInfo = requestBody as EventInfo
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${props.authToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      }

      return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/events/${eInfo.id}`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.result && res.result === `success`) {
            setCreateEventDialogOpen(false)
            fetchEvents(authToken, setEventsLoading, handleEventsLoaded, (err)=>console.error(err))
            return true;
        } else {
            console.error(`Backend Error in handleEventDelete`);
            res.reason && console.error(`Reason: ${res.reason}`);
            return false;
        }
      })
      .catch((reason) => {
          console.error('Fetch Exception in handleEventDelete')
          console.error(`Reason: ${reason}`)
          return false;
      })
    }else{
      // delete event series
      const eSeries = requestBody as EventSeriesProps
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${props.authToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      }

      return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/event_series/${eSeries.id}`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.result && res.result === `success`) {
            setCreateEventDialogOpen(false)
            fetchEvents(authToken, setEventsLoading, handleEventsLoaded, (err)=>console.error(err))
            return true;
        } else {
            console.error(`Backend Error in handleEventDelete`);
            res.reason && console.error(`Reason: ${res.reason}`);
            return false;
        }
      })
      .catch((reason) => {
          console.error('Fetch Exception in handleEventDelete')
          console.error(`Reason: ${reason}`)
          return false;
      })
    }
  }

  const handleRSVPToggle =(eid:number)=> {
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${props.authToken}` }
    }
    
    return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/events/${eid}/rsvp`, requestOptions)
    .then(res => res.json())
    .then((res) => {
      if (res.result && res.result === `success`) {
          setCreateEventDialogOpen(false)
          fetchEvents(authToken, setEventsLoading, handleEventsLoaded, (err)=>console.error(err))
          return true;
      } else {
          console.error(`Backend Error in handleRSVPToggle`);
          res.reason && console.error(`Reason: ${res.reason}`);
          return false;
      }
    })
    .catch((reason) => {
        console.error('Fetch Exception in handleRSVPToggle')
        console.error(`Reason: ${reason}`)
        return false;
    })
  }

  const handleEventFormSubmit = (requestBody:EventCreationDetails | EventSeriesProps | EventInfo)=>{
    // TODO: Timezone Conversion of request Body
    // EventCreation Details has 4 strings
    // Event Series Props has 4 Dates
    // Event info has 2 separate
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${props.authToken}`, 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody)
    }

    if(Object.keys(requestBody).includes("startTime") && Object.keys(requestBody).includes("id")){
      const eInfo = requestBody as EventInfo

      //Convert from local to GMT
      const convertedStart = localToGMT(eInfo.startTime,eInfo.startTime,`America/New_York`);
      const convertedEnd = localToGMT(eInfo.endTime,eInfo.endTime,`America/New_York`);
      const newReqBody = {...eInfo, startTime: convertedStart.dateTime, endTime: convertedEnd.dateTime};
      const newRequestOptions = {...requestOptions, body: JSON.stringify(newReqBody)};

      // Editing existing event instance
      return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/events/${eInfo.id}`, newRequestOptions )
      .then(res => res.json())
      .then((res) => {
          if (res.result && res.result === `success`) {
              setCreateEventDialogOpen(false)
              fetchEvents(authToken, setEventsLoading, handleEventsLoaded, (err)=>console.error(err))
              return true;

          } else {
              console.error(`Backend Error in SetEvent`);
              console.error(res)
              res.reason && console.error(`Reason: ${res.reason}`);
              return false;
          }
      })
      .catch((reason) => {
          console.error('Fetch Exception in SetEvent')
          console.error(`Reason: ${reason}`)
          return false;
      })
    }

    if(Object.keys(requestBody).includes("eventsStart") && Object.keys(requestBody).includes("id")){
      const eSeries = requestBody as EventSeriesProps

      //Convert from local to GMT
      const convertedStart = localToGMT(eSeries.eventsStart,eSeries.eventStartTime,`America/New_York`);
      const convertedEnd = localToGMT(eSeries.eventsEnd,eSeries.eventEndTime,`America/New_York`);
      const newReqBody = {...eSeries, eventsStart: convertedStart.date, eventStartTime: convertedStart.time, eventsEnd: convertedEnd.date, eventEndTime: convertedEnd.time};
      const newRequestOptions = {...requestOptions, body: JSON.stringify(newReqBody)};

      // Editing existing event series
      return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/event_series/${eSeries.id}`, newRequestOptions)
      .then(res => res.json())
      .then((res) => {
          if (res.result && res.result === `success`) {
              setCreateEventDialogOpen(false)
              fetchEvents(authToken, setEventsLoading, handleEventsLoaded, (err)=>console.error(err))
              return true;

          } else {
              console.error(`Backend Error in SetEventSeries`);
              res.reason && console.error(`Reason: ${res.reason}`);
              return false;
          }
      })
      .catch((reason) => {
          console.error('Fetch Exception in SetEventSeries')
          console.error(`Reason: ${reason}`)
          return false;
      })
    }

    // Creating new event (series)

    //Convert from local to GMT
    const eSeries = requestBody as EventCreationDetails
    const convertedStart = localToGMT(eSeries.eventsStart,eSeries.eventStartTime,`America/New_York`);
    const convertedEnd = localToGMT(eSeries.eventsEnd,eSeries.eventEndTime,`America/New_York`);
    console.log(convertedStart, convertedEnd);
    const newReqBody = {...eSeries, eventsStart: convertedStart.date, eventStartTime: convertedStart.time, eventsEnd: convertedEnd.date, eventEndTime: convertedEnd.time};
    const newRequestOptions = {...requestOptions, body: JSON.stringify(newReqBody)};

    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/event/create', newRequestOptions)
      .then(res => res.json())
      .then((res) => {
          if (res.status && res.status === `success`) {
              setCreateEventDialogOpen(false)
              fetchEvents(authToken, setEventsLoading, handleEventsLoaded, (err)=>console.error(err))
              return true;
          } else {
              console.error(`Backend Error in CreateEvent`);
              res.reason && console.error(`Reason: ${res.reason}`);
              return false;
          }
      })
      .catch((reason) => {
          console.error('Fetch Exception in CreateEvent')
          console.error(`Reason: ${reason}`)
          return false;
      })
  }

  React.useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${props.authToken}` }
    }

    if(props.loggedInUser && props.authToken) {

      fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/events/canCreate`, requestOptions)
        .then((res) => res.json())
        .then((res) => {
          if(res.result && res.result === `success`) {
            if(res.permission === "true") {
              setUserCanCreate(true);
            } else {
              setUserCanCreate(false);
            }
          } else {
            console.error(`Backend Error in canCreate`);
            res.reason && console.error(`Reason: ${res.reason}`);
            setUserCanCreate(false);
          }
        })
    } else {
      setUserCanCreate(false);
    }
  },[props.loggedInUser, props.authToken ])

  return (
    <div style={{height:windowHeight-375, display:'flex', flexDirection:'column', alignItems:'center'}}>
      <ClickableCalendar
        loggedInUser={props.loggedInUser}
        authToken={props.authToken}
        events={calendarEvents}
        onEventCancel={()=>{setCreateEventDialogOpen(false)}}
        onEventSubmit={handleEventFormSubmit}
        onEventDelete={handleEventDelete}
        onEventRSVPToggle={handleRSVPToggle}
      />
      {userCanCreate &&<div 
          id='create-event-button' 
          onClick={()=>setCreateEventDialogOpen(true)}
      >
          Create New Event
      </div>}
      <dialog style={{zIndex:20, height:'75%', position:'absolute', top:100, width:'60%'}} open={createEventDialogOpen}>
        <EventForm 
          onCancel={()=>setCreateEventDialogOpen(false)}
          loggedInUser={props.loggedInUser} 
          authToken={props.authToken} 
          onSubmit={handleEventFormSubmit}
          onRSVPToggle={handleRSVPToggle}
          readonly={false}
        />
      </dialog>
    </div>
  )
}

export default EventCalendar
