import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";

const Profile = () =>
{
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    const [userMetadata, setUserMetadata] = useState(null);
    const [userName, setUserName] = useState("");
    const [usereMail, setUsereMail] = useState("");
    //const [usereMailVerified, setUsereMailVerified] = useState(false);
    const [userPicture, setUserPicture] = useState("");
    

    useEffect(() => {
        const getUserMetadata = async () => {
      
          try {
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                scope: "read:current_user",
              },
            });

            let userDetailsByIdUrl = "";

            if(user!)
            {
                userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;
            }
      
            const metadataResponse = await fetch(userDetailsByIdUrl, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
      
            const { name, email, picture, user_metadata } = await metadataResponse.json();
            
            setUserName(name);
            setUsereMail(email);
            //setUsereMailVerified(email_verified);
            setUserPicture(picture);
            setUserMetadata(user_metadata);
          } catch (e: any) {
            console.log("profile=>getUserData: " + e.message);
          }
        };
      
        getUserMetadata();
      }, [getAccessTokenSilently, user]);


      
    if (isLoading)
    {
        return(
            <React.Fragment>
                <div>Loading ...</div>
            </React.Fragment>
        );
    }

    if(isAuthenticated)
    {
        if(user!)
        {
            return(
                <React.Fragment>
                    <div>
                        <img src={userPicture} alt={userName} />
                        <h2>Name: {userName}</h2>
                        <p>e-mail: {usereMail}</p>
                        <h3>User Metadata</h3>
                        {userMetadata ? (<pre>{JSON.stringify(userMetadata, null, 2)}</pre>) : ("No user metadata defined")}
                    </div>
                </React.Fragment>
            );
        }
        else
        {
            return(
                <React.Fragment>
                    <div>Unable to retrieve user data2.</div>
                </React.Fragment>
            );
        }
    }
    else
    {
        return(
            <React.Fragment>
                <div>isAuthenticated = |{isAuthenticated}|</div>
                <div>Unable to retrieve user data1.</div>
            </React.Fragment>
        );
    }
};
export default Profile;