/*
  Top level component for the venue editor, contains form on left, opens preview dialog on submit
*/

import React, { useEffect, useState, SyntheticEvent } from 'react'
import Select, {SingleValue} from 'react-select';
import { VenueForm, VenueMap, VenueSelector } from './components'
import { VenueDetails, createVenue, updateVenue } from './venueModels'
import { getAllVenues, getVenueDetails, useWindowDimensions } from './utils'
import './index.css'
import { CreateVenueInput } from './venueModels';

interface VenueEditorProps {
  loggedInUser?: number,
  authToken?: string,
}

export const VenueEditor:React.FC<VenueEditorProps> = (props)=>{
  const { loggedInUser, authToken } = props
  const [isCreating, setIsCreating] = useState(false)
  const [venues, setVenues] = useState<VenueDetails[]>([])
  const [venueEntry, setVenueEntry] = useState<VenueDetails | CreateVenueInput>()
  const [venuesLoading, setVenuesLoading] = useState(false)
  const [venuesLoadingFailed, setVenuesLoadingFailed] = useState(false)
  const { windowHeight, windowWidth } = useWindowDimensions()

  function fetchVenues(){
    setVenuesLoadingFailed(false)
    setVenuesLoading(true)
    fetch(
      process.env.REACT_APP_REFLEBULA + '/a1/tga/venues',
      {
        headers: authToken ? 
          { 'Authorization': `Bearer ${authToken}` } : 
          { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
      }
    ).then((response) => {
        if (!response.ok) {
            throw response
        } else {
            return response.json()
        }
    }).then(
      r=>{
        console.log(r.data)
        setVenues(r.data)
      }
    )
    .catch(r=>{setVenuesLoadingFailed(true); console.error(r)})
    .finally(()=>setVenuesLoading(false))
  }

  useEffect(()=>{
    if(venues.length === 0){
      fetchVenues()
    }
  },[])

  function handleVenueSelect(e:SingleValue<VenueDetails>) {
    e && setVenueEntry(venues.find(v=>v.id === e.id))
  }

  function handleUpdateSuccess(){
    fetchVenues()
    setVenueEntry(undefined)
    setIsCreating(false)
  }

  function handleVenueSubmit(v:VenueDetails | CreateVenueInput){
    if((v as VenueDetails).id){
      // If there is an id in submission it is updating an existing venue
      if(authToken) {
        updateVenue((v as VenueDetails), authToken).then(()=>handleUpdateSuccess())
      } else {
        console.error('Could not update venue, no valid auth token')
      }
    }else{
      let entry = v as CreateVenueInput
      // If there is no id in the submission it is creating a new venue
      if(authToken && loggedInUser) { 
        createVenue({...entry, uid:loggedInUser}, authToken).then(()=>handleUpdateSuccess())
      }else{
        console.error('Could not create venue, no valid auth token')
      }
    }
  }

  function handleVenueCreate() {
    if(isCreating){
      setVenueEntry(undefined)
      setIsCreating(false)
    }else{
      setVenueEntry({
        name: '',
        detailed_directions: '',
        introduction: '',
        private: true,
        street_address: ''
      } as CreateVenueInput)
      setIsCreating(true)
    }
  }
  // TODO: Insert footer height to subtract from container instead of hardcoded value
  if(venues){
    return (
      <div style={{display:'flex', flexDirection:'column', height:windowHeight-375}}>
      <link rel="stylesheet" href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css" />
      <div className='venue-editor-controls-item'>
        {isCreating ? 
          <h3>Enter information for new venue:</h3>
          :
          <VenueSelector venues={venues} onChange={handleVenueSelect}/>
        }
        <div onClick={handleVenueCreate} className='venue-editor-button'>{isCreating ? 'Cancel' : 'Create'}</div>
      </div>
      { venueEntry && <VenueForm containerClassname='venue-editor-form-item' onSubmit={(e)=>handleVenueSubmit(e)} venue={venueEntry} onVenueEntryChange={(v:any)=>{setVenueEntry({...v});}}/> }
      { venueEntry && <VenueMap containerClassName='venue-editor-map-item' key={JSON.stringify(venueEntry)} venue={venueEntry} /> }
    </div>
  )
}

  //TODO: Implement spinner / suspense for when venue not defined
  return <div>Loading...</div>
}