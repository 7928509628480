import React from 'react';
import CollapsableContent from './CollapsableContent';
import { UserDetails } from '../../models/user/userInfo'
import { EventInfo, EventType } from '../../models/event/eventModel'
import { ToggleRSVPtoEvent } from '../../models/event/userEventModel'

interface RegistrationCollapsableProps {
    startingIsCollapsed: boolean,
    registrantList: UserDetails[],
    eventInfo: EventInfo,
    eventType: EventType,
    user?: UserDetails,
    authToken ?: string,
    registeredContent ?: any,
    unregisteredContent ?: any,
    onRsvpToggle ?: () => void,
    collapsableTitle ?: string,
    registrationsName ?: string,
    registrationsVerb ?: string
}

interface EventTypeSpecific {
    collapsableTitle: string,
    registrationsName: string,
    registeredContent: any,
    unregisteredContent: any
}

const RegistrationCollapsable = (props: RegistrationCollapsableProps) => {

    const [isRegistered, setIsRegistered] = React.useState<boolean>(props.registrantList.filter((usr) => usr.id === props.user?.id).length > 0);

    const now = new Date();

    const isEventinPast = props.eventInfo.startTime < now;

    const authUser = {
        user: props.user?.id,
        authToken: props.authToken
    }

    const toggleRsvpOnClick = async () => {
        if(await ToggleRSVPtoEvent(props.eventInfo.id, authUser)) {
            setIsRegistered(!isRegistered);
            props.onRsvpToggle && props.onRsvpToggle();
        }
    }

    const notLoggedIn = (verb: string) => (
        <div>
            <h4>You need to login to {verb}!</h4>
        </div>
    )

    const standardRegisteredContent = (
        <div>
            <h4>You're RSVPed for this event! You may now sign up for and create Sessions!</h4>
            <button onClick={() => toggleRsvpOnClick()}>Un-RSVP from event!</button>
        </div>
    )

    const standardUnegisteredContent = (
        <div>
            <h4>You can RSVP for this event!</h4>
            <button onClick={() => toggleRsvpOnClick()}>RSVP to event!</button>
        </div>
    )

    const specificInfo = props.eventType.name === "Standard" ? {
        collapsableTitle: "RSVP", 
        registrationsName: "RSVPs",
        registrationsVerb: "RSVP",
        registeredContent: standardRegisteredContent,
        unregisteredContent: standardUnegisteredContent
    } : 
    props.eventType.name === "Premium" ? {
        collapsableTitle: props.collapsableTitle ? props.collapsableTitle : "RSVP", 
        registrationsName: props.registrationsName ? props.registrationsName : "RSVPs",
        registrationsVerb: props.registrationsVerb ? props.registrationsVerb : "RSVP",
        registeredContent: props.registeredContent ? props.registeredContent : standardRegisteredContent ,
        unregisteredContent: props.unregisteredContent ? props.unregisteredContent : standardUnegisteredContent
    } : {
        collapsableTitle: "RSVP", 
        registrationsName: "RSVPs",
        registrationsVerb: "RSVP",
        registeredContent: standardRegisteredContent,
        unregisteredContent: standardUnegisteredContent
    }

    const numOfRegistrants = props.registrantList.length;

    if(specificInfo === undefined){
        return(<div></div>)
    }

    return (
        <div>
            <CollapsableContent title={specificInfo.collapsableTitle} startingIsCollapsed={props.startingIsCollapsed}>
                {isEventinPast ? 
                <div>
                    <h4>This event has already occured.</h4>
                </div> : 
                <div>
                    <p>{specificInfo.registrationsName} : {numOfRegistrants} / {props.eventInfo.slots}</p>
                    {props.user ? (isRegistered ? specificInfo.registeredContent : specificInfo.unregisteredContent) : notLoggedIn(specificInfo.registrationsVerb)}
                </div>}
            </CollapsableContent>
        </div>
    )

}

export default RegistrationCollapsable;