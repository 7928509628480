import React, { Fragment, useRef, useState } from 'react';
import Reaptcha from 'reaptcha';
import './emailcontactform.css';
import EMailer from '../adapters/emailer'

interface EmailContactFormProps
{
    defaultSubject: string
}

function EmailContactForm(props: EmailContactFormProps)
{
    const captcha = useRef<Reaptcha>(null);

    const [captchaToken, setCaptchaToken] = useState(false);
    const [fieldMessageSent, setFieldMessageSent] = useState(false);
    const [fieldName, setFieldName] = useState("");
    const [fieldEMail, setFieldEMail] = useState("");
    const [fieldEMailVerify, setFieldEMailVerify] = useState("");
    const [fieldSubject, setFieldSubject] = useState(props.defaultSubject);
    const [fieldMessage, setFieldMessage] = useState("");

/*
    let state =
    {
        fieldName: '',
        fieldEmail: '',
        fieldEmailVerify: '',
        fieldSubject: props.defaultSubject,
        fieldMessage: '',
        recaptchaResult: '',
        captchaToken: false,
        fieldMessageSent: false
    };*/

    const onLoad = () =>
    {
        setCaptchaToken(false);
    };





    const onVerify = () =>
    {
        setCaptchaToken(true);
    };



// TODO: actually implement usage of onExpire and onClick
// they are commented out to suppress warnings for now
/*
    const onExpire = () =>
    {
        setCaptchaToken(false);
    };
    




    const onClick = () =>
    {
        captcha.current!.renderExplicitly();
        //{(!fieldMessageSent) && 
    };*/




    function isEmailSame()
    {
        return(fieldEMail === fieldEMailVerify);
    }


    function isFormFilled()
    {
        return (fieldName !== '' && fieldEMail !== '' && fieldMessage !== '' && fieldSubject !== '');
    }





    function handleChangeName(event: { target: { value: React.SetStateAction<string>; }; })
    {
        setFieldMessageSent(false);
        setFieldName(event.target.value);
        //console.log('logged: ' + event.target.value);
    }





    function handleChangeEMail(event: { target: { value: React.SetStateAction<string>; }; })
    {
        setFieldMessageSent(false);
        setFieldEMail(event.target.value);
        //TODO: Actually do email verification
    }





    function handleChangeEMailVerify(event: { target: { value: React.SetStateAction<string>; }; })
    {
        setFieldMessageSent(false);
        setFieldEMailVerify(event.target.value);
    }





    function handleChangeSubject(event: { target: { value: React.SetStateAction<string>; }; })
    {
        setFieldMessageSent(false);
        setFieldSubject(event.target.value);
    }





    function handleChangeMessage(event: { target: { value: React.SetStateAction<string>; }; })
    {
        setFieldMessageSent(false);
        setFieldMessage(event.target.value);
    }





    function handleFormReset(event: { preventDefault: () => void; })
    {
        setFieldName("");
        setFieldEMail("");
        setFieldEMailVerify("");
        setFieldMessage("");
        setFieldMessageSent(false);

        event.preventDefault();
    }





    function handleFormSubmit(event: { preventDefault: () => void; })
    {
        //for now, console log everything and reset
        if(event)
        {
            /* probably not needed anymore
            let dat = {
                name: fieldName,
                email: fieldEMail,
                subj: fieldSubject,
                msg: fieldMessage
            };*/

            console.log(fieldName);
            console.log(fieldEMail);
            console.log(fieldSubject);
            console.log(fieldMessage);
            
            const sender = new EMailer({contactEmail: '', contactMessage: ''});
            setFieldMessageSent(true);
            sender.sendEMail(fieldName, fieldEMail, fieldSubject, fieldMessage);



            captcha.current!.reset();
            //Don't die
            event.preventDefault();
        }
    }





    let status;
    let reset;

    if(fieldMessageSent)
    {
        status = <p className="formstatustext">Message Sent!</p>
        reset = <button className="resetbutton" type="reset" value="Reset Form" onClick={handleFormReset}>Reset Form</button>
    }
    else if(!isFormFilled())
    {
        status=<p className="formstatustext">Please fill in all fields.</p>
    }
    else if(!isEmailSame())
    {
        status=<p className="formstatustext">Email does not match.</p>
        //captcha.current!.reset();
    }
    else if(!fieldMessageSent && !captchaToken)
    {
        status = <p className="formstatustext">Please complete Captcha.</p>
        captcha.current!.renderExplicitly();
    }
    else
    {
        status = <button type="submit" value="Submit" onClick={handleFormSubmit}>Submit</button>
    }

    return(
        <Fragment>
            <form className="formbox">
                <input disabled={fieldMessageSent} className="smalltextinput" placeholder="Your name..." value={fieldName} onChange={handleChangeName} />

                <input disabled={fieldMessageSent} className="smalltextinput" placeholder="Your e-mail address..." value={fieldEMail} onChange={handleChangeEMail} />

                <input disabled={fieldMessageSent} className="smalltextinput" placeholder="Your e-mail address again, just to be sure..." value={fieldEMailVerify} onChange={handleChangeEMailVerify} />

                <select disabled={fieldMessageSent} className="smalltextinput" id="subject"  value={fieldSubject} onChange={handleChangeSubject}>
                    <option value="General Inquiry or Feedback">General Inquiry or Feedback</option>
                    <option value="Registration">Registration</option>
                    <option value="Scheduled Game Calendar Access">Scheduled Games Calendar Access</option>
                    <option value="Library Request">Library Request</option>
                    <option value="Programming/Event Ideas">Programming/Event Ideas </option>
                    <option value="Exhibit/Sponsorship Offer">Exhibit/Sponsorship Offer</option>
                    <option value="Website Issue">Website Issue</option>
                    <option value="Other">Other</option>
                </select>

                <textarea disabled={fieldMessageSent} className="largetextinput" placeholder="Anything else you'd like us to know..." id="message" value={fieldMessage} onChange={handleChangeMessage} />

                <div className="captchabutton">
                    {(!fieldMessageSent) && <Reaptcha ref={captcha} sitekey="6Le8lxcUAAAAAAnmL-kL7Zv7RnMdz5YaqPvo0ZmV" onLoad={onLoad} onVerify={onVerify} explicit />}
                </div>

                <div className="formstatus">
                    {status}
                </div>

                <div>
                    {reset}
                </div>
            </form>
        </Fragment>
    );
}
export default EmailContactForm;

