import React from 'react';

const eventOverview = (props: any) =>
(
  <aside className="eventOverview">
    <header className="eventOverview-header">
    </header>
    <main className="eventOverview-body">
      <p>
      It's time for another fun-filled day of gaming with TGA's next Quarterly!
      </p>
      <p>
        All-day open gaming from 10AM to midnight at Courtyard Marriott in Natick around the corner from the Natick/Route 30 exit off the Pike and next to the Natick Collection (Mall).
        Anyone of game-playing age who is interested in playing board games is welcome.
      </p>

      <p>
        Gamacea will take place in the Commons and Walden function rooms at the hotel.
        Using the following layout we have 120 seats in the Commons and 42 seats in the Walden for a grand total of 162 seats across 24 tables.
      </p>

      <b>What is the Quarterly?</b>

      <p>
        The Quarterly is an event where we gather en mass on a Saturday for a day primarily filled with "open gaming", camaraderie, and dining. We host a Quarterly four times a year, once each calendar quarter; hence, the name.
      </p>

      <p>
        With an average attendance of 70+ gamers, finding enough players for large party games like Code Names, Shadow Hunters, and Two Rooms and a Boom becomes much more likely to happen.
      </p>

      <p>
        With a span of 14 hours, it is feasible to play those games that take 3+ hours that just never seem to happen at your weeknight game nights.
      </p>

      <p>
        At past Quarterlies, we have additionally had game designers demonstrate their prototypes, gaming podcasters visit, and door prizes. As these other features present themselves, we will announce them on this site.
      </p>


      <b>What is "Open Gaming"?</b>

      <p>
        In a nutshell, ad hoc gaming or simply playing whatever games you would like.
        In practice, it is a large group of gamers gathered to play games and then splitting up into many smaller pick-up games.
        Feel free to bring and share your own games or not. If not, you can simply arrive and play other people's games.
      </p>


      <b>How do I join a game if I don't know anyone else at the Quarterly?</b>

      <p>
        Please refer to our <button className="buttonlink" onClick={() => props.navcallback("eventPlayerMatching")}>Player Matching</button> section.
      </p>

      <b>What does the Quarterly look like?</b>
      <p>Check out our photos from the past three Quarterlies (links will open in new tabs):</p>
      <a href="https://www.meetup.com/TabletopGamersAlliance/photos/27720592/">2017Q1</a><br />
      <a href="https://www.meetup.com/TabletopGamersAlliance/photos/26939173/">2016Q1</a><br />
      <a href="https://www.meetup.com/TabletopGamersAlliance/photos/27038861/">2016Q2</a><br />
      <a href="https://www.meetup.com/TabletopGamersAlliance/photos/27251560/">2016Q3</a><br />
      <a href="https://www.meetup.com/TabletopGamersAlliance/photos/27648865/">2016Q4</a><br />
      <br />
      <b>Do I need to attend the entire 14 hours?</b>
      <p>You can if you like, but you don't have to attend for the entire 14 hours.</p>

      <b>Can I leave and then come back before the Quarterly ends?</b>
      <p>Yes. Many people will go out for lunch and/or dinner and then return for more gaming.</p>

      <b>Do I have to be a member of TGA to attend?</b>

      <p>
        You do not! The event is open to the general public!
      </p>

      <p>
        However, becoming a member of TGA is free! You can <a href="https://www.meetup.com/TabletopGamersAlliance/?action=join" target="_blank"  rel="noreferrer">join TGA on Meetup.com here.</a>
      </p>


      <b>Do I have to RSVP to attend?</b>

      <p>
        RSVP'ing is not required.
        However, it is very much appreciated if you do RSVP, because it helps show everyone the overall level of interest in the event
        and helps us better determine if the facilities we have rented are adequate for the number of people expected to attend.
      </p>

      <p>
        You must become a member of TGA on meetup in order to RSVP. Becoming a member of TGA is free! You can <a href="https://www.meetup.com/TabletopGamersAlliance/?action=join" target="_blank"  rel="noreferrer">join TGA on Meetup.com here.</a>
      </p>


      <b>Is there a difference between RSVP'ing and registering?</b>

      <p>
        Yes. RSVP'ing is simply just announcing your intent to attend. Registering is paying the entry fee to attend the event.
        You can get more details about registering on our <button className="buttonlink" onClick={() => props.navcallback("registration")}>Registration</button> page.
      </p>
    </main>
    <footer className="landing-footer">
    </footer>
  </aside>
);

export default eventOverview;
