import React, { useEffect, useRef,  useState} from 'react'
import { LatLng, LatLngTuple } from 'leaflet'
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet'
import { CreateVenueInput, VenueDetails } from '../venueModels'
import { getAddressLatLon } from '../utils'
import { MoonLoader } from 'react-spinners'
import './VenueMap.css';

interface venueMapProps {
  venue?:VenueDetails | CreateVenueInput,
  containerClassName?:string
}

export const VenueMap = (props:venueMapProps) => {
  const [venueLatLon, setVenuseLatLon] = useState<LatLngTuple|null>()
  const [isFetchingAddress, setIsFetchingAddress] = useState(false)

  const name = props.venue?.name
  const street_address = props.venue?.street_address
  const containerClassName = props.containerClassName
  
  useEffect(()=>{
    if(street_address){
      setIsFetchingAddress(true)
      setVenuseLatLon(null)
      getAddressLatLon(street_address)
      .then(ll=>setVenuseLatLon(ll))
      .catch(()=>setVenuseLatLon(null))
      .finally(()=>setIsFetchingAddress(false))
    }
  },[props.venue?.street_address])

  if(!props.venue){
    return (
      <div className={props.containerClassName || 'venue-map-container' }>
        No Venue to Display
      </div>
    )
  }

  if(!street_address){
    return (
      <div className='venue-map-container'>
        Enter an address to display a map of the venue.
      </div>) 
  }

  if(street_address && typeof(venueLatLon) === 'undefined'){
    return (
      <div className='venue-map-container'>
        <MoonLoader size={50} aria-label={'Loading Spinner'}/>
        <h4>{isFetchingAddress ? 'Fetching Data...' : 'Loading Map...'}</h4>
      </div>
      )
  }

  if(street_address && venueLatLon === null){
    return (
      <div className={containerClassName || 'venue-map-container'}>
        <h4 style={{textAlign:'center', padding:16}}>{`Unable to load map for ${street_address}`}</h4>
      </div>
      )
  }

  return(
    <MapContainer 
      center={venueLatLon as LatLngTuple}
      zoom={13} 
      scrollWheelZoom={false}
      className={containerClassName || 'venue-map-container'}
    >
      <link rel="stylesheet" href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css" />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={venueLatLon as LatLngTuple}>
        <Popup>
          {name}
        </Popup>
      </Marker>
    </MapContainer>
  )
}

export default VenueMap