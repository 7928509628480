import React, {useEffect, useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";

const PostLogRouter = () =>
{
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [userTGAFirstLog, setUserTGAFirstLog] = useState(null);

    useEffect(() =>
    {
        const getUserData = async (userAuth0Name: string) =>
        {
            try
            {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                      scope: "read:current_user",
                    },
                });
                const userDataResponse = await fetch(process.env.REACT_APP_REFLEBULA + "/a1/tga/user/byA0/" + userAuth0Name + "", { headers: { Authorization: `Bearer ${accessToken}`,}, });
                const {first_login} = await userDataResponse.json();
                setUserTGAFirstLog(first_login);
            }
            catch (e: any)
            {
                console.log("getUserData: " + e.message);
            }
        };





        const getUserMetadata = async () => {
          try {
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                scope: "read:current_user",
              },
            });

            let userDetailsByIdUrl = "";

            if(user!)
            {
                userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;
            }
      
            const metadataResponse = await fetch(userDetailsByIdUrl, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
      
            const {user_id} = await metadataResponse.json();
            
            //setUserName(name);
            //setUsereMail(email);
            //setUsereMailVerified(email_verified);
            //setUserPicture(picture);
            //setUserMetadata(user_metadata);
            
            //console.log("auth0ID = " + user_id);
            //console.log("name = " + name)

            getUserData(user_id);
          } catch (e: any) {
            console.log("getUserMetaData: " + e.message);
          }
        };




      
        getUserMetadata();
    }, [getAccessTokenSilently, user, userTGAFirstLog]);





      if (isLoading)
      {
          return(
              <React.Fragment>
                  <div>Loading ...</div>
              </React.Fragment>
          );
      }





    if(isAuthenticated)
    {
        if(user! && userTGAFirstLog != null)
        {
            if(userTGAFirstLog)
            {
                window.location.href = "/profilesetup";

                return(
                    <React.Fragment>
                        <div>
                            Redirecting...
                        </div>
                    </React.Fragment>
                );
            }
            else
            {
                window.location.href = "/";

                return(
                    <React.Fragment>
                        <div>
                            Redirecting...
                        </div>
                    </React.Fragment>
                );
            }
        }
        else
        {
            return(
                <React.Fragment>
                    <div>Attempting to fetch user data...</div>
                </React.Fragment>
            );
        }
    }
    else
    {
        return(
            <React.Fragment>
                <div>isAuthenticated = |{isAuthenticated}|</div>
                <div>Unable to retrieve user data.</div>
            </React.Fragment>
        );
    }
};
export default PostLogRouter;