import React from 'react';

const venueLodging = (props: any) =>
(
  <div>
    <b>Courtyard Marriott Natick Room Block</b>
    <p>We have a small room block for gamers visiting from afar in order for you to take the most advantage of the 32 straight hours of gaming! These rooms area provided by the hotel at a discounted base rate of $109 for the night of June 30th (additional guest room taxes apply).</p>
    <a href="https://www.marriott.com/meeting-event-hotels/group-corporate-travel/groupCorp.mi?resLinkData=TABLETOP%20GAMERS%20ALLIANCE%5EBOSNF%60TABTABA%7CTABTABB%60109%60USD%60false%604%606/30/18%607/1/18%605/30/18&app=resvlink&stop_mobi=yes">Reserve your hotel room using this special link now!</a>
  </div>
);

export default venueLodging;
