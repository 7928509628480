import React from 'react';

const venueBeverages = (props: any) =>
(
  <div>
    <p>There will be water stations set up in both function rooms and the hotel bistro will serve coffee in the morning. With it being a hotel, there should be beverage vending machines around too.</p>
    <p>The hotel bar will be open for service from 12 p.m. - 10 p.m. Attendees who buy drinks from the bar are allowed to take them anywhere inside the hotel, including back to the function rooms for gaming. In accordance with the open container law and the terms of the hotel's liquor license, you are not allowed to transport your drinks outside of the building.</p>
    <b>This has never been a problem with our group, but it should be known that our contract with the venue contains a clause prohibiting anyone from bringing in outside alcohol.</b>
  </div>
);

export default venueBeverages;
