import React from 'react';
import './index.css';
import * as Pages from './components/pages';
import GamaMenu from './menu';
import { Routes, Route } from "react-router-dom";

export default class Gamacea2024 extends React.Component {
  state = {
    navState: "landing"
  }

  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state =
    {
      navState: "landing",
    };
  }



  navClick(newnav: string) {
    this.setState({ navState: newnav });
  }


  renderSelectedPage(selectedPage: string) {
    type pageType = keyof typeof Pages;

    let myMap = new Map<string, React.ElementType>();

    Object.keys(Pages).forEach((key) => { myMap.set(key.toString(), Pages[key as pageType]) })

    const Page: React.ElementType = myMap.get(selectedPage)!;

    return (<Page navcallback={(a: string) => this.navClick(a)} />);
  }


  render() {
    return (
      <React.Fragment>
        <div className="gamacea">
          <div className="containergama">
            <GamaMenu callback={(a) => this.navClick(a)} />
            <div className="infoBar">
              <p className="infoDate">
                June 15th, 2024 - June 16th, 2024
              </p>
              <p className="infoVenue">
                Hampton Inn Natick | 319 Speen Street, Natick, MA
              </p>
            </div>
            {this.renderSelectedPage(this.state.navState)}
          </div>
        </div>
      </React.Fragment>
    );
  }


}

