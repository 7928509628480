import React from 'react';

const registration = (props: any) =>
(
  <aside className="registration">
    <header className="registration-header">
    </header>
    <main className="registration-body">
      <div className="registration-left">
        <h2>Registration</h2>

        {/*<h3>Registration will open at noon on May 6th!</h3>*/}
        <p>
          Register for Gamacea 2024 via our Square store-front:  <a href="https://tabletopga.square.site/" target="_blank" rel="noreferrer">https://tabletopga.square.site/</a> &nbsp; <a href="https://tabletopga.square.site/" target="_blank" rel="noreferrer"><img src="https://www.tabletopgamersalliance.com/i/p/2019/q1/square_logo.png" alt="" /></a>
        </p>
        <p>
          Online registration for entry to the Tabletop Gamers Alliance Gamacea 2024
          for the weekend of June 15, 2024 and June 16, 2024. After check-out, your name and/or e-mail address
          will be added to the registration list. When checking in at the event at the door, just
          mention that you registered online.
        </p>
        <p>
          By purchasing a Gamacea 2024 registration, you acknowledge that you will abide by the <button className="buttonlink" onClick={() => props.navcallback("eventPolicy")}>Gamers Alliance In-Person Events Policy</button> while in attendance at Gamacea.
        </p>
        <p>
          If purchasing multiple registrations, list all the names you are purchasing
          registrations for during check-out in the "Write a note to the seller" field.
        </p>
        <p>
          Online registrations for this event will be available at two different price tiers:
        </p>
        <ul>
          {/*<li style={{textDecoration: 'line-through'}}>*/}
          <li style={{textDecoration: 'line-through'}}>
            Early-Bird: $35 / person
            <ul>
              <li>LIMITED quantity available</li>
              <li>Add the Early-Bird registration to the shopping basket at our Square online store to register at this special rate.</li>
            </ul>
          </li>
          <br />
          <li>
            Online (regular): $40 / person
            <ul><li>available until June 7th</li></ul>
          </li>
        </ul>
        <p>
          Registration at the door will be open daily from 10AM to 8PM and available at varying rates:
        </p>
        <ul>
          <li>
            Weekend: $50 / person
          </li>
          <li>
            Saturday-Only: $35 / person
          </li>
          <li>
            Sunday-Only: $25 / person
          </li>
        </ul>
      </div>
    </main>
    <footer className="landing-footer">
    </footer>
  </aside>
);

export default registration;
