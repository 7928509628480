import * as React from 'react';

const eventRsvpdiscuss = (props: any) =>
(
  <div>
    <p>Attendees typically RSVP* and discuss plans prior to the Quarterly in the comments section on these event pages:</p>
    <ul>
      <li><a href="https://www.meetup.com/tabletopgamersalliance/events/254718793/" target="_blank" rel="noreferrer">TGA's Quarterly meetup Event Page</a></li>
      <li><a href="https://www.facebook.com/events/2238068229758715/" target="_blank" rel="noreferrer">TGA's Quarterly Facebook Event Page</a></li>
    </ul>
    <p>RSVP'ing and participating in the commenting on TGA's meetup page requires joining TGA's meetup group** +: <a href="https://www.meetup.com/TabletopGamersAlliance/?action=join" target="_blank" rel="noreferrer">Join TGA on Meetup.com</a></p>

    <p><a href="https://www.facebook.com/TabletopGamersAlliance/" target="_blank" rel="noreferrer">We also have a Facebook Page.</a></p>

    <p>Do I have to RSVP to attend?</p>
    <p>*RSVP'ing is not required. However, it is very much appreciated if you do RSVP, because it helps show everyone the overall level of interest in the event and helps us better determine
      if the facilities we have rented are adequate for the number of people expected to attend.</p>

    <p>You must become a member of TGA on meetup in order to RSVP. Becoming a member of TGA is free! You can <a href="https://www.meetup.com/TabletopGamersAlliance/?action=join" target="_blank" rel="noreferrer">join TGA on Meetup.com here.</a></p>

    <p>Is there a difference between RSVP'ing and registering?</p>
    <p>Yes. RSVP'ing is simply just announcing your intent to attend. Registering is paying the entry fee to attend the event. You can get more details about registering on our <button className="buttonlink" onClick={() => props.navcallback("registration")}>Registration page.</button></p>

    <p>** The event is open to the general public. You do not need to be a TGA member to attend. Though, consider joining TGA if you would like to learn about the other events and group activities we organize.</p>

    <p>+ Meetup.com accounts are FREE!</p>
  </div>
);

export default eventRsvpdiscuss;
