import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import EventCreator from '../../scheduling/EventCreator';
//import EmailContactForm from '../../emailcontactform/emailcontactform'

interface IMeta {
  tga_id: any
}

interface userQuery {
  result: string
}

interface eventID {
  event: number
}

interface eventQuery {
  data: eventID[]
}

interface roleID {
  role: number
}

interface permsQuery {
  data: roleID[]
}

interface regMutation {
  event: number,
  user: number
}

function ScheduleGame(props: any) {
  const { user, getAccessTokenSilently } = useAuth0();
  const [userToken, setUserToken] = React.useState<string>("");
  const [userTGAid, setUserTGAID] = React.useState<number>(0);
  const [userStatus, setUserStatus] = React.useState<number>(0);

  const getTGAid = React.useCallback((dat: IMeta) => {
    return (dat.tga_id);
  }, []);

  React.useEffect(() => {

    const getUserCredentials = async () => {
      if (user!) {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
            scope: "read:current_user",
          },
        });
        setUserToken(accessToken);

        let userDetailsByIdUrl = "";


        userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;


        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();

        var temp_id = getTGAid(user_metadata);

        //console.log("tga_id = " + temp_id);
        setUserTGAID(temp_id);
        //console.log("tga_id state = " + userTGAid);
      } else {
        setUserTGAID(0);
      }
    }
    getUserCredentials();
  }, [getAccessTokenSilently, getTGAid, user])

  React.useEffect(() => {

    const getUserStatus = async (user: number): Promise<number> => {
      const requestOptions = {
        method: 'GET',
        headers: { "Access-Control-Allow-Origin": "*", 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
      }
      // Check if user exists
      const userQuery: userQuery = await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/user/' + user, requestOptions)
        .then(response => response.json())
      if (userQuery.result === 'failure') { setUserStatus(0); return 0; }
      // Check if user is attendinging event
      const eventQuery: eventQuery = await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/eventUsers/user/' + user, requestOptions)
        .then(response => response.json())
      console.log(eventQuery.data);
      console.log(eventQuery.data.includes({ event: 1 }));
      if (eventQuery.data.filter((evt) => evt.event === 1).length === 0) { setUserStatus(1); return 1; }
      // Check if user has event editing permissions
      const permsQuery: permsQuery = await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/userRoles/user/' + user, requestOptions)
        .then(response => response.json())
      if (permsQuery.data.filter((evt) => (evt.role === 1 || evt.role === 2)).length === 0) { setUserStatus(2); return 2; }
      setUserStatus(3);
      return 3;
    }

    userTGAid && getUserStatus(userTGAid);
  }, [userTGAid])

  const eventRegister = async (): Promise<boolean> => {
    if (userTGAid == null) {
      return Promise.resolve(false);
    }
    const user_to_add = userTGAid
    const reqbody: regMutation = {
      user: user_to_add,
      event: 1
    }
    const requestOptions = {
      body: JSON.stringify(reqbody),
      method: 'POST',
      headers: {
        'Content-Type': `application/json`,
        "Access-Control-Allow-Origin": "*",
        'Authorization': userToken
      }
    }
    console.log(requestOptions);
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/eventUsers/addmap', requestOptions)
      .then(res => res.json())
      .then((result) => {
        if (result.status === 'success') {
          setUserStatus(2);
          return true
        }
        else {
          console.log(result)
          return false
        }
      })
  }

  const user_header = (sta: number) => {
    switch (sta) {
      case 0:
        return <b>You are not logged in! Log into TGA to access RSVP Features!</b>;
      case 1:
        return (<div><b>Hello new user! This is a Beta version of our new scheduler. Eventually, we will tie your registration with your user to permit you access automatically.
          For now, please click this button to be granted access to RSVP-ability. Please do not click if you are not registered yet!</b> <button
            onClick={() => eventRegister()}
          >Click here to enable RSVP!</button></div>);
      case 2:
        return <b>You may now RSVP to events! To gain event creation permissions, please request in the discord channel or contact us through our contact form.</b>;
      case 3:
        return <b>You may now create Events!</b>;
      default:
        return <b>Something Bad has occured if you're seeing this.</b>

    }

  }

  return (
    <div>
      <div>
        {user_header(userStatus)}
        <br></br>
        <b>For more help regarding User Accounts and Game scheduling, click <a href="https://www.tabletopgamersalliance.com/files/Getting_Started_with_Your_TGA_Online_Account.pdf" target="_blank" rel="noreferrer">here!</a></b>
      </div>
      <div>
        <br></br>
        {props.userStatus > 2 && <EventCreator userId={props.userId} token={props.token} />}
      </div>
    </div>
  )
}

export default ScheduleGame;
