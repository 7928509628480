import React from 'react';

const venueLodging = (props: any) =>
(
  <div>
    <b>Hampton Inn Room Reservations</b>
    <p>There is no dedicated room block this year, however, you can still <a href="https://www.hilton.com/en/hotels/bosnthx-hampton-boston-natick/" target="_blank" rel="noreferrer">reserve a room for the hotel here!</a></p>
  </div>
);

export default venueLodging;
