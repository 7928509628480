import * as React from 'react';

function Schedule(props: any) {
  const [data, setData] = React.useState({} as any);

  //JSON Loader
  React.useEffect(() => {
    fetch("https://www.tabletopgamersalliance.com/dat/2018/q3/gcal.json")
      .then((response) => response.json())
      .then((json) => { setData(json); });
  }, []);

  return (
    <div>
      <p>Some people schedule games, especially games that require 3+ hours of play, ahead of time to drum up interest for players.
        Scheduling the longer games also allows for people to familiarize themselves with the rules before the day of the Quarterly.
        Anyone is allowed to <button className="buttonlink" onClick={() => props.navcallback("scheduleGame")}>Schedule a Game.</button></p>
      <div>
        {data.gcal?.map((postData: { link: string | undefined; title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; start: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; end: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; desc: any; }) => {
          return (
            <div>
              <a href={postData.link} target="_blank" rel="noreferrer">{postData.title}</a>
              <p>Start {postData.start}</p>
              <p>Estimated End {postData.end}</p>
              <div dangerouslySetInnerHTML={{ __html: postData.desc }} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Schedule;
