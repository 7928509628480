import React from 'react';

const registration = (props: any) =>
(
  <aside className="registration">
    <header className="registration-header">
    </header>
    <main className="registration-body">
      <div className="registration-left">
        <h2>Registration</h2>

        <h3>Registration will open at noon on April 10th!</h3>
        <p>
          Register for Gamacea 2023 via our Square store-front:  <a href="https://tabletopga.square.site/" target="_blank" rel="noreferrer">https://tabletopga.square.site/</a> &nbsp; <a href="https://tabletopga.square.site/" target="_blank" rel="noreferrer"><img src="https://www.tabletopgamersalliance.com/i/p/2019/q1/square_logo.png" alt="" /></a>
        </p>
        <p>
          Online registration for entry to the Tabletop Gamers Alliance Gamacea 2023
          for the weekend of June 10, 2023 and June 11, 2023. After check-out, your name and/or e-mail address
          will be added to the registration list. When checking in at the event at the door, just
          mention that you registered online.
        </p>
        <p>

          IMPORTANT: Gamacea 2023's <button className="buttonlink" onClick={() => props.navcallback("eventCoViD")}>CoViD mitigation policy</button> will be finalized and announced on May 7th.  By purchasing a registration, you acknowledge that you will abide by the CoViD mitigation policy while in attendance at Gamacea.
        </p>
        <p>
          If purchasing multiple registrations, list all the names you are purchasing
          registrations for during check-out in the "Write a note to the seller" field.
        </p>
        <p>
          Online registrations for this event will be available at two different price tiers:
        </p>
        <ul>
          {/*<li style="text-decoration: line-through;">*/}
          <li>
            Early-Bird: $30 / person
            <ul>
              <li>LIMITED quantity available</li>
              <li>Add the Early-Bird registration to the shopping basket at our Square online store to register at this special rate.</li>
            </ul>
          </li>
          <br />
          <li>
            Online (regular): $35 / person
            <ul><li>available until June 2nd</li></ul>
          </li>
        </ul>
        <p>
          Registration at the door will be open daily from 10AM to 8PM and available at varying rates:
        </p>
        <ul>
          <li>
            Weekend: $40 / person
          </li>
          <li>
            Saturday-Only: $30 / person
          </li>
          <li>
            Sunday-Only: $20 / person
          </li>
        </ul>
      </div>
    </main>
    <footer className="landing-footer">
    </footer>
  </aside>
);

export default registration;
