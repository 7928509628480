import { UserDetails } from '../user/userInfo';
import moment from 'moment';

export interface DB_Error {
    result: string,
    reason: string,
    exception?: string,
    message?: string
}

export type ValuePiece = Date | null;

export type Value = any;//ValuePiece | [ValuePiece, ValuePiece]

export interface EventCreationDetails {
    start: Value,
    end: Value,
    name: string,
    info: string,
    venue: number,
    slots: number,
    owner: number,
    website_url: string,
    highest_table_number: number,
    lowest_table_number: number,
    rsvp?: boolean
}

export interface EventInfo {
    startTime: Value,
    endTime: Value,
    end_time: Value,
    highestTableNumber: number | null,
    id: number,
    info: string,
    lowestTableNumber: number | null,
    name: string,
    owner: string,
    slots: number,
    start_time: Value,
    venue: number,
    website_url?: string,
    mainMapImage ?: string
}

export interface getAllEventsInput {
    rangeStart: Date,
    rangeEnd: Date
}

export interface GETAllEvents {
    result: string,
    data: EventInfo[]
}

function isGetAllEvents(resp: GETAllEvents | DB_Error): resp is GETAllEvents {
    return (resp as GETAllEvents).result === 'success';
}

interface GETSpecificEvent {
    result: string,
    data: EventInfo
}

function isGetSpecificEvent(resp: GETSpecificEvent | DB_Error): resp is GETSpecificEvent {
    return (resp as GETSpecificEvent).result === 'success';
}

export interface eventRsvpList {
    count: number,
    data: UserDetails[]
}

interface GETRsvpList extends eventRsvpList {
    result: string
}

function isRsvpList(resp: GETRsvpList | DB_Error): resp is GETRsvpList {
    return (resp as GETRsvpList).result === 'success';
}

export interface EventType{
    id: number,
    name: string
}

export const getAllEvents = (input: getAllEventsInput): Promise<EventInfo[] | undefined> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==', 'Content-Type': 'application/json' },
        body: JSON.stringify(input)
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/events/range', requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.log('Fetch Error in getAllEvents')
                console.log(`HTTP Response Code: ${response.status}`)
                throw ('Bad Fetch');
            } else {
                return response;
            }
        })
        .then(res => res.json())
        .then((res: DB_Error | GETAllEvents) => {
            if (isGetAllEvents(res)) {
                return res.data;
            } else {
                console.log(`Backend Error in getAllEvents Reason ${res.reason}`)
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return undefined;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in getAllEvents')
            console.log(`Reason: ${reason}`)
            return undefined;
        })
}

export const getSpecificEvent = (eventId: number): Promise<EventInfo | undefined> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/events/' + eventId, requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.log('Fetch Error in getSpecificEvent')
                console.log(`HTTP Response Code: ${response.status}`)
                throw ('Bad Fetch');
            } else {
                return response;
            }
        })
        .then(res => res.json())
        .then((res: DB_Error | GETSpecificEvent) => {
            if (isGetSpecificEvent(res)) {
                return res.data;
            } else {
                console.log(`Backend Error in getSpecificEvent Reason ${res.reason}`)
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return undefined;
            }
        })
        .then((res) => {
            if (!res) {
                return;
            }
            //TODO: Change to GMT->EST
            const tz = 'America/New_York';
            const startTime = moment.tz(res.startTime,`GMT`);
            const endTime = moment.tz(res.endTime,`GMT`);
            const result = { ...res, startTime: res.startTime ? startTime.clone().tz(tz).toDate(): new Date(), endTime: res.endTime ? endTime.clone().tz(tz).toDate(): new Date() }
            return result;
        })
        .catch((reason) => {
            console.log('Fetch Exception in getSpecificEvent')
            console.log(`Reason: ${reason}`)
            return undefined;
        })
}

export const getRSVPsToEvent = (eventId: number): Promise<eventRsvpList | undefined> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' },
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/eventUsers/event/' + eventId, requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.log('Fetch Error in getRSVPsToEvent')
                console.log(`HTTP Response Code: ${response.status}`)
                throw ('Bad Fetch');
            } else {
                return response;
            }
        })
        .then(res => res.json())
        .then((res: DB_Error | GETRsvpList) => {
            if (isRsvpList(res)) {
                return res as eventRsvpList;
            } else {
                console.log(`Backend Error in getRSVPsToEvent Reason ${res.reason}`)
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return undefined;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in getRSVPsToEvent')
            console.log(`Reason: ${reason}`)
            return undefined;
        })
}

export const createEvent = (input: EventCreationDetails, authToken: string): Promise<boolean> => {
    const requestBody = {
        start_time: input.start,
        end_time: input.end,
        uid: input.owner,
        vid: input.venue,
        rsvpl: input.rsvp,
        ...input
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/event/create', requestOptions)
        .then(res => res.json())
        .then((res) => {
            if (res.status && res.status === `success`) {
                return true;
            } else {
                console.log(`Backend Error in CreateEvent`);
                res.reason && console.log(`Reason: ${res.reason}`);
                return false;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in CreateEvent')
            console.log(`Reason: ${reason}`)
            return false;
        })
}

export const updateEvent = (input: EventInfo, authToken: string): Promise<boolean> => {
    const requestBody = {
        uid: input.owner,
        vid: input.venue,
        ...input,
        id: undefined,
        owner: undefined,
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(input)
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/event/' + input.id + (input.owner ? '?uid=' + input.owner : ''), requestOptions)
        .then(res => res.json())
        .then((res) => {
            if (res.result && res.result === `success`) {
                return true;
            } else {
                console.log(`Backend Error in UpdateVenue`);
                res.reason && console.log(`Reason: ${res.reason}`);
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return false;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in UpdateVenue')
            console.log(`Reason: ${reason}`)
            return false;
        })
}

export const isUserOwnerofEvent = (eid: number, uid: number, authToken: string): Promise<boolean> => {
    const requestBody = {
        uid: uid
    }
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    }
    return fetch(process.env.REACT_APP_REFLEBULA + `/tga/event/ocheck/` + eid, requestOptions)
        .then(res => res.json())
        .then((res) => {
            if (res.status && res.status === `success`) {
                return res.result;
            } else {
                console.log(`Backend Error in UpdateVenue`);
                res.reason && console.log(`Reason: ${res.reason}`);
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return false;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in UpdateVenue')
            console.log(`Reason: ${reason}`)
            return false;
        })
}