export interface DB_Error {
    result: string,
    reason: string,
    exception?: string,
    message?: string
}

export interface UserDetails {
    auth0_id: string,
    avatar: string,
    bgg_user: string,
    created_at: Date,
    email: string,
    facebook_url: string,
    first_login: boolean,
    first_name: string,
    id: number,
    instagram_url: string,
    last_logout: Date,
    last_name: string,
    meetup_id: string,
    postcode: string,
    tga_discord_user: string,
    tiktok_url: string,
    tiwtch_url: string,
    twitter_url: string,
    user_name: string,
    website_url: string,
    youtube_url: string
}

function isUserDetails(resp: UserDetails | DB_Error): resp is UserDetails {
    return (resp as UserDetails).user_name ? true : false;
}

export const getUserDetails = async (user: number): Promise<UserDetails | undefined> => {
    console.log("User Details", user);
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
    return await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/user/' + user, requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.log('Fetch Error in getUserDetails')
                console.log('HTTP Error Code: ${response.status}')
                throw ('Bad Fetch')
            } else {
                return response
            }
        })
        .then(res => res.json())
        .then((res: UserDetails | DB_Error) => {
            if (isUserDetails(res)) {
                return res;
            } else {
                console.log(`Backend Error in getUserDetails Reason ${res.reason}`)
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return undefined;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in getVenueDetails Private')
            console.log(`Reason: ${reason}`)
            return undefined;
        })
}

export const getUserDetailsByName = async (user: string): Promise<UserDetails | undefined> => {
    console.log("User Details", user);
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
    return await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/user/byname/' + user, requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.log('Fetch Error in getUserDetails')
                console.log('HTTP Error Code: ${response.status}')
                throw ('Bad Fetch')
            } else {
                return response
            }
        })
        .then(res => res.json())
        .then((res: UserDetails | DB_Error) => {
            if (isUserDetails(res)) {
                return res;
            } else {
                console.log(`Backend Error in getUserDetails Reason ${res.reason}`)
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return undefined;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in getVenueDetails Private')
            console.log(`Reason: ${reason}`)
            return undefined;
        })
}