import React from 'react';
import { MenuItem, ControlledMenu, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { IoMdArrowDropdown } from 'react-icons/io';
import QuarterlyYearMenu from './QuarterlyYear/QuarterlyYear'
import './mainnav.css';
import { Link } from "react-router-dom"

interface GamaMenuProps {
  callback: { (a: string): void };
  showAdminFunctions: boolean;
  showTitanBuster ?: boolean;
}

export default function TGAMenu(props: GamaMenuProps) {
  const gamingMenuItemRef = React.useRef(null);
  const adminMenuItemRef = React.useRef(null);
  const [isOpenGaming, setOpenGaming] = React.useState<boolean | undefined>();
  const [isOpenAdmin, setIsOpenAdmin] = React.useState<boolean | undefined>();


  return (
    <React.Fragment>

      <Link className="TGAMenuTopItemimg" to="/">
        <img alt="TGA logo small" src="https://www.tabletopgamersalliance.com/i/p/2018/q1/TGA_logo_avatar_(nova)_new.png" />
      </Link>

      <div className="TGAMenuTop" onMouseLeave={() => setOpenGaming(false)}>
        <div onMouseEnter={() => setOpenGaming(true)}>
          <button ref={gamingMenuItemRef} className="TGAMenuTopItem"
            onMouseDown={() => { setOpenGaming(!isOpenGaming) }}>
            <div className="TGAMenuTopButtonText">Gaming<IoMdArrowDropdown className="TGAMenuArrow" /></div>
          </button>
        </div>

        <ControlledMenu arrow={false} anchorRef={gamingMenuItemRef} state={isOpenGaming ? 'open' : 'closed'} onClose={() => setOpenGaming(false)} transition={true}>

        <MenuItem className="QuarterlyMenu"><Link className="QuarterlyMenu" to="/TitanBuster/recruiting">Titan Buster Recruiting</Link></MenuItem>

          {
            props.showTitanBuster &&
            <MenuItem className="QuarterlyMenu"><Link className="QuarterlyMenu" to="/TitanBuster/temp_schedule">Titan Buster</Link></MenuItem>
          }


          <SubMenu label="Gamacea">
            <MenuItem className="QuarterlyMenu"><Link className="QuarterlyMenu" to="/2024/gamacea">2024</Link></MenuItem>
            <SubMenu label="Archives">
              <MenuItem className="QuarterlyMenu"><Link className="QuarterlyMenu" to="/2023/gamacea">2023</Link></MenuItem>
              <MenuItem className="QuarterlyMenu"><Link className="QuarterlyMenu" to="/2022/gamacea">2022</Link></MenuItem>
              <MenuItem className="QuarterlyMenu"><Link className="QuarterlyMenu" to="/2019/gamacea">2019</Link></MenuItem>
              <MenuItem className="QuarterlyMenu"><Link className="QuarterlyMenu" to="/2018/gamacea">2018</Link></MenuItem>
            </SubMenu>
          </SubMenu>

          <SubMenu label="Quarterlies">
            <QuarterlyYearMenu year="2019" visible={[true, false, true, true]} />
            <SubMenu label="Archives">
              <QuarterlyYearMenu year="2018" visible={[true, false, true, true]} />
              <QuarterlyYearMenu year="2017" visible={[true, true, true, true]} />
              <QuarterlyYearMenu year="2016" visible={[true, true, true, true]} />
              <QuarterlyYearMenu year="2015" visible={[true, true, true, true]} />
              <QuarterlyYearMenu year="2014" visible={[true, true, true, true]} />
              <QuarterlyYearMenu year="2013" visible={[true, true, true, true]} />
            </SubMenu>
          </SubMenu>

        </ControlledMenu>
      </div>

      <Link className="TGAMenuTopItem" to="/calendar">
        Event Calendar
      </Link>

      <Link className="TGAMenuTopItem" to="/library">
        Library
      </Link>

      <Link className="TGAMenuTopItem" to="/about">
        About
      </Link>

      <Link className="TGAMenuTopItem" to="/contact">
        Contact
      </Link>

      {
        props.showAdminFunctions &&
          <div className="TGAMenuTop" onMouseLeave={() => setIsOpenAdmin(false)}>
            <div onMouseEnter={() => setIsOpenAdmin(true)}>
              <button ref={adminMenuItemRef} className="TGAMenuTopItem"
                onMouseDown={() => { setIsOpenAdmin(!isOpenAdmin) }}>
                <div className="TGAMenuTopButtonText">
                  Admin<IoMdArrowDropdown className="TGAMenuArrow" />
                </div>
              </button>
            </div>

            <ControlledMenu arrow={false} anchorRef={adminMenuItemRef} state={isOpenAdmin ? 'open' : 'closed'} onClose={() => setIsOpenAdmin(false)} transition={true}>
              <MenuItem className="QuarterlyMenu">
                <Link className="QuarterlyMenu" to="/admin/venues">Manage Venues</Link>
              </MenuItem>
            </ControlledMenu>
        </div>
    }

    </React.Fragment>
  );
}
