import React from 'react';

const venueFood = (props: any) =>
(
  <div>
  <b>Hotel Bistro</b>
  <p>The hotel bistro serves breakfast, coffee, and cafe fair until 11AM.</p>
  <b>Hotel Food Policy</b>
  <p>Generally, hotel function rooms have a no-outside food policy. However, it should be fine to bring in snacks and such, but please refrain from bringing in delivery or take-out.</p>
  <b>Off-Site Food</b>
  <p>For off-site food options, the venue is located next to the Natick Mall with its multiple food courts and restaurants along with the multitude of restaurants within the mall's immediate vicinity.
    The options range from Taco Bell to Legal Seafood and everything in-between!</p>
  </div>
);

export default venueFood;
