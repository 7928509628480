import React from 'react';

const venueLodging = (props: any) =>
(
  <div>
    <b>Hampton Inn Room Reservations</b>
    <p>To Be Announced</p>
  </div>
);

export default venueLodging;
