import * as React from 'react';
import EmailContactForm from '../../emailcontactform/emailcontactform'

const scheduleGame = (props: any) =>
(
  <div>
  <p>For the Quarterly, we are managing scheduled games through google calendar.
    After receiving scheduling access to the calendar, you will be able to add events directly to the calendar.</p>
  <p>Please create events using the following guidelines:</p>
  <ul>
    <li>General</li>
    <ul>
      <li> After adding the game event to the calendar, inform das_ninja if you would like an additional sign-up event created on the TGA meetup page.</li>
      <li>Reference the <button className="buttonlink" onClick={() => props.navcallback("eventMap")}>Event Map</button> for table numbers</li>
      <li>Party/loud games should be scheduled for tables in the Commons room only</li>
      <li>Schedule games on the day of the quarterly only, any events outside of that day will be ignored</li>
      <li>Round your time block off to the nearest half hour</li>
      <ul>
        <li>example: The game is expected to run for 75 minutes, the event on the calendar should be scheduled for 90 minutes/1.5 hours</li>
      </ul>
    </ul>
    <li>google calendar Event Entry Fields</li>
    <ul>
      <li>Event Title: Table X - [name of game + additional title text]</li>
      <ul>
        <li>examples:</li>
        <ul>
          <li>Table 5 - Jack's Agricola (will teach beginners)</li>
          <li>Table 9 - Eclipse with all expansions (experienced players)</li>
          <ul>
            <li>Example for running multiple sessions of the same game:</li>
            <li>Table 6 - das_ninja's Sheriff of Nottingham (1 of 2)</li>
            <li>Table 6 - das_ninja's Sheriff of Nottingham (2 of 2)</li>
          </ul>
          <li>Table 15 - Euphoria [2/6 spots remaining]</li>
          <li>Table 22 - Manhattan Project [no spots remaining]</li>
        </ul>
      </ul>
      <li>Calendar: TGA Scheduled Games</li>
      <li>Description (optional): [additional details, related links for the scheduled game, number of spots available, link to sign-up event on TGA meetup page]</li>
      <li>Attachment (optional): [attach any reference documents or files that players may find useful]</li>
      <li>Visibility: Calendar default</li>
    </ul>
  </ul>
  <p>Submin the following form to request schedule access to the google calendar.</p>

  <EmailContactForm defaultSubject="Scheduled Game Calendar Access"></EmailContactForm>
</div>
);

export default scheduleGame;
