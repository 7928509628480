import React from 'react';

const eventPlayerMatching = (props: any) =>
(
  <div>
    <p> Wondering how to find other people to game with at the Quarterly? This is the section for you! Here, we discuss ways to find gamers that want to play what you do before the Quarterly and on the day of.</p>

    <b>Before the Quarterly</b>
    <ul>
      <li>Many TGA members often discuss games they plan to bring, games they're seeking players for, games they're seeking teachers for, and much more.</li>
      <li>We have started using Discord chat rooms to discuss gaming plans for our events and have accordingly created a <a href="https://discord.com/invite/Dk4AHMr" target="_blank" rel="noreferrer">Quarterly Discussion Chat Room</a> on our Discord server.</li>
      <ul>
        <li><a href="https://discord.com/" target="_blank" rel="noreferrer">Discord</a> is a FREE chat/message board program that runs on Windows, MacOS, Android, iOS, and even your browser. The above link will get you started,
          but you will need to register a free Discord account in order to get the most out of the chat room.</li>
        <ul>
          <li>Important tip for using Discord, especially on your mobile device: after joining our server, Mute each channel that are you not interested in receiving notifications or change
            your notification settings for the server to @-mentions only. Yes, it's a bit of a pain to opt-out this way, but we are joining a contingent of other Discord admins to lobby the developers to implement an channel opt-in policy.</li>
        </ul>
        <li>There is more message board-like discussion on <a href="https://www.meetup.com/TabletopGamersAlliance/events/264207803/" target="_blank" rel="noreferrer">TGA's Quarterly meetup Event Page</a></li>
      </ul>
      <li>The <button className="buttonlink" onClick={() => props.navcallback("schedule")}>Scheduled Games</button> section - Some people schedule games, especially games that require 3+ hours of play, ahead of time to drum up interest for players.
        Scheduling the longer games also allows for people to familiarize themselves with the rules before the Quarterly. Anyone is allowed to <button className="buttonlink" onClick={() => props.navcallback("scheduleGame")}>Schedule a Game.</button></li>
    </ul>
    <b>During the Quarterly</b>
    <p>At the event, there will be a bank of the following signs available:</p>
    <ul>
      <li>Jump Start area - This is easiest and least-intimidating option for new people that do not know anyone at the Quarterly. If you are looking to join a game, simply stand near the "Jump Start" sign and games looking for players will invite you to join.
        If you are looking for players to play your game, stand near the "Jump Start" sign with at least the box top from the game to show everyone that you are looking for players for that game.
        Often, those looking for players and those looking for games in the Jump Start area often match up together and quickly begin gaming.</li>
      <li>Players Wanted signs - Take one of these signs from the bank and place it on your table to lure potential players to you. Then, return the sign to the bank as soon as you have filled your game.</li>
      <li>Teachers Wanted signs - Take one of these signs from the bank and place it on your table to draw the attention of a person that can teach you the game you have set up. Then, return the sign to the bank as soon as someone has volunteered to teach you.</li>
    </ul>
  </div>
);

export default eventPlayerMatching;
