import React from 'react';
import $ from 'jquery';
//import $ = require('jquery');

class EMailer extends React.Component
{
  constructor(props: {}){
    super(props);
    this.state = {
      contactEmail: '',
      contactMessage: ''
    };
  }

  sendEMail(name: string, email: string, subj: string, msg: string)
  {
    let dat = {
      name: name,
      email: email,
      subj: subj,
      msg: msg
    };

    // TODO: e-mail CGI script must be refactored to accept JSON input so that we can use the native javascript fetch() function

/*    (async () =>
      {
        //const requestOptions = ;

        const response = await fetch('https://www.tabletopgamersalliance.com/cgi-bin/email.cgi', {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dat)
        });

        const data = await response.json();
        console.log("response: " + JSON.stringify(data));

//        .then((response) => response.json())
//        .then(data => { console.log("response: " + data); }).catch((err) => { console.log("response error: " + err.message); console.log("dat: " + JSON.stringify(dat)); });
      })();
*/
/*
    fetch('https://www.tabletopgamersalliance.com/cgi-bin/email.cgi', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(dat)
    })
    .then((response) => response.json())
    .then(data => { console.log("response: " + data); }).catch((err) => { console.log("response error: " + err.message); console.log("dat: " + JSON.stringify(dat)); });
*/

    $.ajax({
        url: 'https://www.tabletopgamersalliance.com/cgi-bin/email.cgi',
        type: 'POST',
        data: dat,
        crossDomain: true,
        cache: false,
        success: function(data)
        {
          console.log('dat = ' + dat);
          console.log('Successfully submitted e-mail to back-end.\n', data);
        },
        // Fail..
        error: function(xhr, status, err)
        {
          console.log(xhr, status);
          console.log("error: " + err);

          console.log(dat.email + ' ' + dat.msg + ' failed to send e-mail to back-end\n');
        }
      });
  }
}

export default EMailer;
