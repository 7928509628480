import React from 'react';

const eventMap = (props: any) =>
(
  <aside className="eventMap">
    <header className="eventMap-header">
      <b>Commons Room</b>
      <p>The Commons room is a large function room the size of most of the rooms we have used for Quarterlies. This will be where the bulk of the open gaming will occur.</p>
      <b>Walden Room</b>
      <p>The Walden room is a smaller function room with 42 seats across 6 tables. This room will be reserved for scheduled games, scheduled teaching games, and games that need a quieter space away from the rumble and outbursts of the large open gaming area in the Commons. Please, play party/loud games in the Commons instead of the Walden.</p>
    </header>
    <main className="eventMap-body">
      <img className="eventMap" src="https://www.tabletopgamersalliance.com/i/q/q1/2019/Commons-Walden-Layout-Actual.png" alt="" />
    </main>
    <footer className="eventMap-footer">
    </footer>
  </aside>
);

export default eventMap;
