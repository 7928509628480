import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import {getSpecificEvent, EventInfo} from "../../models/event/eventModel"
import {getUserDetails, UserDetails} from "../../models/user/userInfo"
import React, { useMemo } from 'react';
import './featureInfo.css'
// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.

interface FeatureInfoProps {
    startTime: any,
    endTime: any,
    name: string,
    owner: string,
    timeZone ?: string
}

const FeatureInfo = (props: FeatureInfoProps) => {

    return  <div>
        <div className="eventTime">
            <p> {moment(props.startTime).format('LLLL')} -- {moment(props.endTime).format('LLLL')}</p>
        </div>
        <div className="eventName">
            <b> {props.name}</b>
        </div>
        <div className="eventHost">
            <p> Hosted By: {props.owner}</p>
        </div>
    </div>
}

export default FeatureInfo;