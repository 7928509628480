import React from 'react';
import EmailContactForm from '../../emailcontactform/emailcontactform'

const contact = (props: any) =>
(
  <aside className="contact">
    <header className="contact-header">
    </header>
    <main className="contact-body">
      <EmailContactForm defaultSubject="Scheduled Game Calendar Access"></EmailContactForm>
    </main>
    <footer className="contact-footer">
    </footer>
  </aside>
);

export default contact;
