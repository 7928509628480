import React from 'react';
import EmailContactForm from '../../emailcontactform/emailcontactform'

const contactpage = (props: any) =>
(
  <div>
  <EmailContactForm defaultSubject="General Inquiry or Feedback"></EmailContactForm>
  </div>
);

export default contactpage;
