import React from 'react';

const landing = (props: any) =>
(
  <aside className="landing">
    <header className="landing-header">
    </header>
    <main className="landing-body">
      <img className="quart-logo" src="https://www.tabletopgamersalliance.com/i/q/2017/q4/adq-2017-10-07_xx-w.jpg" alt="" />
      <div className="gamacea-index-left">
        <br /><br />
      </div>
    </main>
    <footer className="landing-footer">
    </footer>
  </aside>
);

export default landing;
