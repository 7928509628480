import React, { useEffect, useReducer, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";


const formReducer = (state: any, event: any) => {
    return {
        ...state,
        [event.name]: event.value
    }
}



const ProfileForm = () => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    const [userTGAid, setUserTGAID] = useState(null);
    const [userTGAName, setUserTGAName] = useState("");

    const [formData, setFormData] = useReducer(formReducer, {});
    const [submitting, setSubmitting] = useState(false);
    const [dupeUser, setDupeUser] = useState(false);
    const [broken, setBroken] = useState(false);
    const [success, setSuccess] = useState(false);


    //const [userMetadata, setUserMetadata] = useState(null);
    //const [userName, setUserName] = useState("");
    //const [usereMail, setUsereMail] = useState("");
    //const [usereMailVerified, setUsereMailVerified] = useState(false);
    //const [userPicture, setUserPicture] = useState("");



    //const [userTGAFirstLog, setUserTGAFirstLog] = useState(null);





    interface IMeta {
        tga_id: any
    }

    const getTGAid = React.useCallback((dat: IMeta) => {
        return (dat.tga_id);
    }, []);

    //function getTGAid(dat: IMeta)
    //{
    //console.log("IMeta = " + JSON.stringify(dat));
    //console.log("tga_id = " + dat.tga_id);
    //return(dat.tga_id);
    //}





    useEffect(() => {
        const getUserData = async (userID: number) => {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                        scope: "read:current_user",
                    },
                });

                const userDataResponse = await fetch(process.env.REACT_APP_REFLEBULA + "/a1/tga/user/" + userID + "", { headers: { Authorization: `Bearer ${accessToken}`, }, });

                const { user_name, email, first_name, last_name } = await userDataResponse.json();

                setUserTGAName(user_name);

                setFormData({ name: "user_name", value: user_name, });
                setFormData({ name: "email", value: email, });
                setFormData({ name: "first_name", value: first_name, });
                setFormData({ name: "last_name", value: last_name, });

                //console.log("user_name = " + user_name);
                //console.log(formData);
            }
            catch (e: any) {
                console.log("editprofile=>getUserData: " + e.message);
            }
        };


        const getUserMetadata = async () => {

            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                        scope: "read:current_user",
                    },
                });

                let userDetailsByIdUrl = "";

                if (user!) {
                    userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;
                }

                const metadataResponse = await fetch(userDetailsByIdUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                const { user_metadata } = await metadataResponse.json();

                //setUserMetadata(user_metadata);

                //console.log("auth0ID = " + user_id);

                var temp_id = getTGAid(user_metadata);

                //console.log("tga_id = " + temp_id);
                setUserTGAID(temp_id);
                //console.log("tga_id state = " + userTGAid);

                getUserData(temp_id);
            }
            catch (e: any) {
                console.log("editprofile=>getUserMetadata: " + e.message);
            }
        };

        getUserMetadata();
    }, [getAccessTokenSilently, getTGAid, user]);





    if (isLoading) {
        return (
            <React.Fragment>
                <div>Loading ...</div>
            </React.Fragment>
        );
    }





    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ name: event.target.name, value: event.target.value, });
    }





    const saveFormData = async () => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                scope: "read:current_user",
            },
        });

        const response = await fetch(process.env.REACT_APP_REFLEBULA + "/a1/tga/user/" + userTGAid + "", { headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' }, method: 'POST', body: JSON.stringify(formData) });

        //var resp = Object.keys(response.json()).map(function(_) { return response.json()[_]; });
        //var resp = Object.entries(response.json());

        if (response.status === 200) {
            setSuccess(true);
        }
        else if (response.status === 400) {
            setDupeUser(true);
            setBroken(true);
        }
        else {
            setBroken(true);
            console.log(`Profile edit save attempt failed: ${response.status} ${response.statusText}`);
            //console.log("r: " + resp.keys());
            throw new Error(`Profile edit save attempt failed: ${response.status} ${response.statusText}`);
        }
    }





    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault(); // Prevent default submission
        setDupeUser(false);
        setBroken(false);
        setSuccess(false);
        setSubmitting(true);

        try {
            await saveFormData();
        }
        catch (e: any) {
            console.log(`Profile edit save failed! ${e.message}`);
        }

        setSubmitting(false);
    }




    const getUserDataSansEffect = async (userID: number) => {
        try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                    scope: "read:current_user",
                },
            });

            const userDataResponse = await fetch(process.env.REACT_APP_REFLEBULA + "/a1/tga/user/" + userID + "", { headers: { Authorization: `Bearer ${accessToken}`, }, });

            const { user_name, email, first_name, last_name } = await userDataResponse.json();

            setUserTGAName(user_name);

            setFormData({ name: "user_name", value: user_name, });
            setFormData({ name: "email", value: email, });
            setFormData({ name: "first_name", value: first_name, });
            setFormData({ name: "last_name", value: last_name, });

            //console.log("editprofile=>getUserDataSansEffect=>user_name = " + user_name);
        }
        catch (e: any) {
            console.log("editprofile=>getUserDataSansEffect: " + e.message);
        }

    };





    const getUserStats = async () => {
        try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                    scope: "read:current_user",
                },
            });

            let userDetailsByIdUrl = "";

            if (user!) {
                userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;

                const metadataResponse = await fetch(userDetailsByIdUrl, {
                    headers: { Authorization: `Bearer ${accessToken}`, },
                });

                const { user_metadata } = await metadataResponse.json();

                /*setFormData({ name: "auth0_id", value: user_id, });
                setFormData({ name: "user_name", value: name, });
                setFormData({ name: "email", value: email, });
                setFormData({ name: "first_login", value: "FALSE", });

                console.log("profilesetup=>getUserStats=>auth0_id: " + user_id);

                getUserDataSansEffect(user_id);*/

                /*const {user_metadata} = await metadataResponse.json();
    
                setUserTGAID(getTGAid(user_metadata));
    
                getUserDataSansEffect(userTGAid);*/

                //setUserMetadata(user_metadata);

                var temp_id = getTGAid(user_metadata);

                //console.log("editprofile=>getUserStats=>tga_id = " + temp_id);
                setUserTGAID(temp_id);

                getUserDataSansEffect(temp_id);
            }
        }
        catch (e: any) {
            console.log("editprofile=>getUserStats: " + e.message);
        }
    }





    if (userTGAName === "") {
        getUserStats();
    }





    if (isAuthenticated) {
        if (user! && userTGAName !== "") {
            //<p>TGA firstlog: {userTGAFirstLog ? "True" : "False"}</p>

            return (
                <React.Fragment>
                    <hr />

                    <div>
                        {submitting &&
                            <p>Attempting to save data to the server...</p>
                        }
                        {broken &&
                            <p>There was a problem saving data to the server.</p>
                        }
                        {success &&
                            <p>Successfully saved data to the server.</p>
                        }
                    </div>

                    <div>
                        <form onSubmit={handleSubmit}>
                            <fieldset>
                                <label>*User Name:
                                    <input style={{ width: "250px", }} type="text" name="user_name" value={formData.user_name || ""} onChange={handleChange} required={true} /> {dupeUser && <span>this user name is currently taken</span>}
                                </label>

                                <br /><br />

                                <label>e-mail:
                                    <input style={{ width: "250px", }} type="text" name="email" value={formData.email || ""} onChange={handleChange} disabled /> currently uneditable
                                </label>

                                <br /><br />

                                <label>First:
                                    <input type="text" name="first_name" value={formData.first_name || ""} onChange={handleChange} />
                                </label>

                                <br /><br />

                                <label>Last:
                                    <input type="text" name="last_name" value={formData.last_name || ""} onChange={handleChange} />
                                </label>
                            </fieldset>

                            <br /><br />

                            <div>
                                <input type="submit" disabled={submitting} />
                                &nbsp; by clicking this "Submit" button, you
                                acknowledge and agree to the Tabletop Gamers
                                Alliance (AKA TGA AKA Gamers Alliance LLC)
                                Online Terms of Service listed below:
                                <ol>
                                    <li>
                                        TGA's record of your e-mail address
                                        will:
                                        <p>
                                            a) Be utilized to differentiate
                                            your user profile data from other
                                            users' profile data.
                                        </p>
                                        <p>
                                            b) Be used by TGA to directly
                                            contact you for the purposes that
                                            you have initiated with TGA such as
                                            TGA account issues, TGA event
                                            registrations, and correspondence
                                            that you have directly opted-in to.
                                        </p>
                                        <p>
                                            c) Only be subject to communication
                                            distribution lists or groups that
                                            you directly opt-in to.
                                        </p>
                                        <p>
                                            d) Not be sold nor distributed to
                                            any parties external to TGA.
                                        </p>
                                    </li>
                                    <li>
                                        Public-facing fields of your user
                                        profile data are socially decent and
                                        not offensive to other TGA Online
                                        users. Any user profile data that TGA
                                        deems in-violation of this clause, will
                                        be subject to sanitization by TGA.  Any
                                        user that repeatedly violates this
                                        clause will be subject to revocation of
                                        their TGA Online account.
                                    </li>
                                </ol>
                            </div>
                        </form>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <div>Attempting to fetch user data...</div>
                </React.Fragment>
            );
        }
    }
    else {
        return (
            <React.Fragment>
                <div>isAuthenticated = |{isAuthenticated}|</div>
                <div>Unable to retrieve user data1.</div>
            </React.Fragment>
        );
    }
};
export default ProfileForm;