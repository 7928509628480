import React from 'react';

const venueFood = (props: any) =>
(
  <div>
    <b>Hotel SKYBOKX 109 Sports Bar & Gril</b>
    <p>The hotel has a Sports Bar and Grill available for any on-site food needs. The hotel will be open:</p>
    <ul>
      <li>On Saturday: From 12PM to approx 11PM</li>
      <li>On Sunday: From 12PM past the end time of Gamacea.</li>
    </ul>
    <b>Hotel Food Policy</b>
    <p>Generally, hotel function rooms have a no-outside food policy. However, it should be fine to bring in snacks and such, but please refrain from bringing in delivery or take-out.</p>
    <b>Off-Site Food</b>
    <p>For off-site food options, the venue is located next to the Natick Mall with its multiple food courts and restaurants along with the multitude of restaurants within the mall's immediate vicinity.
      The options range from Taco Bell to Legal Seafood and everything in-between!</p>
  </div>
);

export default venueFood;
