import React from 'react';

const landing = (props: any) =>
(
  <aside className="landing">
    <header className="landing-header">
    </header>
    <main className="landing-body">
      <img className="gamacea-logo" src="https://www.tabletopgamersalliance.com/i/p/2018/gamacea/gamacea_banner.png" alt="" />
      <div className="gamacea-index-left">
        <h1>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          The cure for all the boredom that ails you!
        </h1>
        <br /><br />
      </div>
    </main>
    <footer className="landing-footer">
    </footer>
  </aside>
);

export default landing;
