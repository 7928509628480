import React from 'react';

const eventCoViD = (props: any) =>
(
    <aside className="evenCoViD">
      <p>May 10th update: we revised one of the clauses from the original policy announced to:</p>
      <p>Attendees may also be asked to wear masks (or remove themselves from the vicinity) if a masked gamer requests it during a game or when approaching a group or individual that is masked. </p>
      <br />
      <p>May 7th update:</p>
      <p>On April 10th, the law to end the CoViD-19 national emergency was signed by the President.  The federal CoViD-19 public health emergency is set to expire on May 11th and the Commonwealth of Massachusetts will also end its state-wide CoViD-19 public health emergency on the same day to align with the end of the federal policy.  Neighboring states will either end their CoViD-19 emergencies to coincide with the end of the federal policy, expire their emergency before the end of May, or have already expired or ended their emergencies prior to May 2023.</p>
      <p>On May 4th, Massachusetts' weekly new case count was 897, dropping below 1,000 for the first time since early July 2021.  The weekly death count was 14, holding below 20 for the past two weeks and has not held as low since early March 2022.  All other New England states have reported either lesser or similar for several weeks or more ahead of Massachusetts.  New York state collectively still reports higher numbers (~4000 weekly new cases and ~45 weekly deaths), however, we rarely have attendees from New York.</p>
      <p>On May 2nd, the Massachusetts Water Resources Authority reported 7-day averages of SARS-COV-2 RNA detected in Eastern Massachusetts wastewater on the decline after a small spike caused by a peak from April 25th's measurements.  May 2nd's 7-day averages of 158 for Southern readings and 205 for Northern readings were last this low in late March 2022 and are significantly lower than the 7-day averages reported from the beginning of 2023 (~2000 for Southern and Northern).  The lowest 7-day averages reported just prior to the April 25th spike were 144 for Southern and 154 for Northern.</p>
      <p>
        We expect that all of these metrics mentioned above will continue to decrease as we approach Gamacea 2023 weekend, June 10th, and we expect that there will be no CoViD-19-related emergencies to be in effect by then either.  Taking all of these factors in mind, our CoViD Mitigation Policy for Gamacea 2023 will be:
        <ul>
          <li>All attendees will be required to sign a waiver for participating in the presence of CoViD-related risks.</li>
          <li>We will not check vaccination status.  However, we strongly encourage all attendees to be "current" (as defined by the CDC) with CoViD-19 vaccines (for most: complete primary vaccine regimen plus one bivalent booster regimen | for people aged 65+ and immuno-compromised: complete primary vaccine regimen plus first bivalent booster regiment plus additional booster regimens as-needed)</li>
          <li>Attendees should not attend if they are knowingly host to any infectious diseases, including but not limited to CoViD-19. We reserve the right to remove anyone that may be considered a health risk to others. </li>
          <li>
            There will be both mask-required and mask-optional space. How much mask-required space will be dependent on demand communicated prior to Gamacea as well as usage during the event.
            <ul>
              <li>We may re-designate space during the event based on demand. This may include removal of mask-required areas if they are not in use and there is not enough room for gaming otherwise.</li>
              <li>Attendees must wear masks at all times while present in the areas that are designated as mask-required.</li>
              <li>Attendees may also be asked to wear masks (or remove themselves from the vicinity) if a masked gamer requests it during a game or when approaching a group or individual that is masked. </li>
              <li>Attendees that prefer mask-required gaming are strongly encouraged to communicate this preference to us over our Discord server, Meetup event page, and Facebook event page.</li>
              <li>Attendees intending to spend the entirety of their time in mask-required settings should make liberal use of discord/meetup/Facebook messaging as well as scheduled games. As the main congregation areas will be in mask-optional space, attempting to find a game while remaining in the mask-required area may prove difficult.</li>
            </ul>
          </li>
          <li>Although we don't expect to do this, we reserve the right to place more strict restrictions in the event cases rise or government institutions institute new states of emergency. Should this occur, we will communicate this as soon as possible. Refunds due to changes in this policy will not be given. Gamers that are concerned about this are encouraged to register at the door.</li>
        </ul>
      </p>
    </aside>
);

export default eventCoViD;
