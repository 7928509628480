import * as React from 'react';

const venueDetails = ((props: any) =>
  <div>
    <iframe className="EmbeddedMap"
    title="venueDirections"
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5901.412874524198!2d-71.38341!3d42.306129!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x51b30ad7146c6cf1!2sCourtyard%20by%20Marriott%20Boston%20Natick!5e0!3m2!1sen!2sus!4v1666837448495!5m2!1sen!2sus"
    width="400"
    height="300"
      //style="border:0;"
    loading="lazy">
    </iframe>

    <br /><br />

    <b>Parking</b>
    <p>The Courtyard has a small lot in front of the lobby, but there is another lot on the opposite side of the building with ample parking for everyone.</p>
    <b>Lodging</b>
    <p>More details about lodging can be found in the <button className="buttonlink" onClick={() => props.navcallback("venueLodging")}>Lodging</button> section.</p>
    <b>Traveling from Boston (sans car)</b>
    <p>If you are traveling from the Boston area and are without car, there are a couple of ways that you can still attend our event.</p>
    <ul>
      <li>Several TGA members with cars live in various parts around the city.
        You may be able to arrange a carpool with one of them if you leave a comment on TGA's Gamacea event page on&nbsp;
        <a href="https://www.meetup.com/tabletopgamersalliance/events/259936787/" target="_blank"  rel="noreferrer">Meetup</a> or&nbsp;
        <a href="https://www.facebook.com/events/415367805697637/" target="_blank"  rel="noreferrer">Facebook</a> or the&nbsp;
        <a href="https://discord.com/invite/Dk4AHMr" target="_blank"  rel="noreferrer">Gamacea Discord channel</a> requesting a carpool.
        Should anyone be able to help, then you two will be able to work out the details via private messages there.</li>
      <li>You can take a Peter Pan bus (<a href="https://peterpanbus.com/" target="_blank"  rel="noreferrer">https://peterpanbus.com/</a>) from South Station to Framingham. It is approximately a 1-mile walk from the bus stop to the hotel.
        Or you can call a cab/Uber to take you the rest of the way.</li>
      <li>Another option is to travel to the Natick commuter rail station and then call a cab/Uber to bring you to the hotel.</li>
    </ul>
  </div>
);

export default venueDetails;
