import CollapsableContent from './CollapsableContent';
import Markdown from 'react-markdown';

interface MarkdownCollapsableProps {
    title: string
    startingIsCollapsed: boolean
    content: string
}

const MarkdownCollapsable = (props: MarkdownCollapsableProps) => {
    return(
        <div>
            <CollapsableContent title={props.title} startingIsCollapsed={props.startingIsCollapsed}>
                <Markdown>{props.content}</Markdown>
            </CollapsableContent>
        </div>
    )
}

export default MarkdownCollapsable;