export interface AuthUser {
    user?: number,
    authToken ?: string
}


export const isUserAdmin = async (authToken: string, user: number) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
    const fetchData = async (id: number) => {
        const data = await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/userRoles/user/' + id, requestOptions).then(res => res.json())
        if (data.result === 'success') {
            return data.data.some((role: any) => {
                if (role.role === 1) {
                    return true;
                }
                return false;
            })
        }
    }
    return fetchData(user);
}

export const canUserCreateVenues = (authToken: string, user: number) => {
    return false;
}

export const canUserCreateEvents = async (authToken: string, user: number): Promise<boolean> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${authToken}` },
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/event/hcheck/' + user, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            if (data.result === 'success') {
                if (data.check_bool === 'true') {
                    return true;
                } else {
                    return false;
                }
            }
            else {
                console.log(`Backend Error in CreateEvent`);
                data.reason && console.log(`Reason: ${data.reason}`);
                return false;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in CanUserCreateEvents')
            console.log(`Reason: ${reason}`)
            return false;
        })
}

export const isUserTitanBusterVerified = async (authToken: string, user: number): Promise<boolean> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
    const fetchData = async (id: number) => {
        const data = await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/userRoles/user/' + id, requestOptions).then(res => res.json())
        if (data.result === 'success') {
            return data.data.some((role: any) => {
                if (role.role === 5) {
                    return true;
                }
                return false;
            })
        }
    }
    return fetchData(user);
}