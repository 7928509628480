import React from 'react';
import EmailContactForm from '../../emailcontactform/emailcontactform'

const librarypage = () =>
(
  <div>
  <p>TGA has a library of games available to our members and attendees of the Quarterly. You may also view the contents of our library on <a href='https://boardgamegeek.com/collection/user/tga_library?own=1&subtype=boardgame&ff=1' target="_blank"  rel="noreferrer">BGG</a>. Unless noted, we do not have any expansions for these games.</p>
  <p><b>Checking-out Games</b></p>
  <p>Until the Quarterly grows large enough to have a permanent library staff and enough space to house the library on-site, we will be utilizing the following reservation procedure:</p>
  <ul>
    <li>Browse our library on this page or <a href='https://boardgamegeek.com/collection/user/tga_library?own=1&subtype=boardgame&ff=1' target="_blank"  rel="noreferrer">BGG</a>. Unless noted, we do not have any expansions for these games.</li>
    <li>Use the contact form at the bottom of this page to communicate to us which titles you would like to check-out at the Quarterly. The deadline for requests is 11:59PM on the night before the Quarterly.</li>
    <li>We'll bring all of the games in everyone's requests to the Quarterly.</li>
    <li>You can pick the game up any time at the Quarterly from the registration desk.</li>
    <li>At the time of check-out, you will leave a photo-ID with the registration desk while you have the game checked-out.</li>
    <li>Play the game at your leisure.</li>
    <li>Return the game to the registration desk and we will return your photo-ID to you.</li>
  </ul>
  <p><b>Library Gallery view under construction.</b></p>
  <p><b>Unless noted, we do not have any expansions for these games.</b></p>
  <EmailContactForm defaultSubject="Library Request"></EmailContactForm>
  </div>
);

export default librarypage;
