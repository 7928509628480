import React from 'react';

const eventMap = (props: any) =>
(
  <aside className="eventMap">
    <header className="eventMap-header">
    </header>
    <main className="eventMap-body">
      <img className="eventMap" src="https://www.tabletopgamersalliance.com/i/p/2022/gamacea/Gamacea2022_Numbered_Layout.png" alt="" />
    </main>
    <footer className="eventMap-footer">
    </footer>
  </aside>
);

export default eventMap;
