import { AuthUser } from '../models/user/userAuthorization';
import moment from 'moment';
import { CalSessionC } from '../components/content/CollapsibleSchedule';

export enum SessionSignUpStatus
{
	notPermitted=0,
	signedUp=1,
	notSignedUp=2,
	relenquishOnly=3
}

interface SessionStruct
{
    bgg_title_link: string,
    end_time: string,
    start_time: string,
    event: number,
    id: number,
    info: string,
    name: string,
    owner: number,
    room: string,
    slots: number,
    table: number,
    highest_table_number: number,
    lowest_table_number: number,
    user_name: string
}
  
interface gSessionQuery
{
    data: SessionStruct[]
}

interface participantQuery
{
    user_name: string
}


export const toggleUserSessionStatus =  async (user: AuthUser, sessionId: number, newState: boolean): Promise<boolean> => 
{
    if(user.authToken === undefined)
    {
        return false;
    }

    const requestOptions = 
    {
        method: 'POST',
        headers:
        {
            'Content-Type': `application/json`,
            "Access-Control-Allow-Origin": "*",
            'Authorization': user.authToken ? `Bearer ${user.authToken}` : ``
        }
    };

    const request = newState ? 
        process.env.REACT_APP_REFLEBULA + '/a1/tga/gsessions/rsvp/' + sessionId + '/user/' + user.user :
        process.env.REACT_APP_REFLEBULA + '/a1/tga/gsessions/rsvp/' + sessionId + '/user/' + user.user + '/remove'
        
    const response = await fetch(request, requestOptions);

    const result = await response.json();

    if (result.result === 'success' || result.status === 'success')
    {
        return true;
    }
    else
    {
        console.log(result);
        return false;
    }
}

export const getGsessionsForEvent = async (eventId: number): Promise<CalSessionC[]|undefined> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
      }

      return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/gsessions/viewgsessions/' + eventId, requestOptions).then(res => res.json()).then(async (res:gSessionQuery) => {
        const partresp = await Promise.all(
            res.data.map(async (sesh: SessionStruct) => {
                return await fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/gsessions/viewgsessions/participants/' + sesh.id, requestOptions).then(response => response.json())
            })
        )


        const calView = res.data.map(((sesh, index) =>
        {
            console.log(sesh);
            const part = partresp[index];
            const slotsused = part.count;

            const particpants = part.data.map((val: participantQuery) =>
            {
              return val.user_name;
            })

            const startGMT = moment.tz(sesh.start_time, "ddd DD MMM YYYY HH:mm:ss GMT", "GMT");
            const endGMT = moment.tz(sesh.end_time, "ddd DD MMM YYYY HH:mm:ss GMT", "GMT");

            const newval: CalSessionC =
            {
                bgg_title_link: sesh.bgg_title_link,
                slots_available: sesh.slots - slotsused,
                owner: sesh.user_name,
                info: sesh.info,
                room: sesh.room,
                table: sesh.table,
                id: sesh.id,
                name: sesh.name,
                title: sesh.name,
                start: startGMT.clone().tz("America/Los_Angeles").toDate(),
                end: endGMT.clone().tz("America/Los_Angeles").toDate(),
                participants: particpants,
                count: index
            }

            return newval;
        }))

        return calView;
    })
}
