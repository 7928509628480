import React from 'react';
import { MenuItem, ControlledMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { IoMdArrowDropdown } from 'react-icons/io';

interface GamaMenuProps {
  callback: { (a: string): void };
}

export default function GamaMenu(props: GamaMenuProps) {
  const refEvent = React.useRef(null);
  const refVenue = React.useRef(null);
  const [isOpenEvent, setOpenEvent] = React.useState(false);
  const [isOpenVenue, setOpenVenue] = React.useState(false);
  return (
    <React.Fragment>
      <div className="gamaMenu">
        <button className="gamaMenuTopItem" onClick={() => props.callback("landing")}>
          Q4 2019
        </button>
        <div className="gamaMenuTop" onMouseLeave={() => setOpenEvent(false)}>
          <div className="gamaMenuTopItem" onMouseEnter={() => setOpenEvent(true)}>
            <button ref={refEvent} className="gamaMenuTop"
              onMouseDown={() => { setOpenEvent(!isOpenEvent) }}>
              Event
              <IoMdArrowDropdown className="gamaMenuArrow" />
            </button>
          </div>
          <ControlledMenu arrow={false} anchorRef={refEvent} state={isOpenEvent ? 'open' : 'closed'} onClose={() => setOpenEvent(false)} transition={true}>
            <MenuItem value="eventOverview" onClick={() => props.callback("eventOverview")}>
              Overview
            </MenuItem>
            <MenuItem value="EventMap" onClick={() => props.callback("eventMap")}>
              Event Map
            </MenuItem>
            <MenuItem value="eventPlayerMatching" onClick={() => props.callback("eventPlayerMatching")}>
              Player Matching
            </MenuItem>
            <MenuItem value="RSVP and Discuss" onClick={() => props.callback("eventRsvpdiscuss")}>
              RSVP and Discuss
            </MenuItem>
            <MenuItem value="Kids Game Club" onClick={() => props.callback("eventKidsGameClub")}>
              Kids Game Club
            </MenuItem>
          </ControlledMenu>
        </div>

        <button className="gamaMenuTopItem" onClick={() => props.callback("registration")}>
          Registration
        </button>

        <button className="gamaMenuTopItem" onClick={() => props.callback("schedule")}>
          Schedule
        </button>


        <button className="gamaMenuTopItem" onClick={() => props.callback("scheduleGame")}>
          Schedule A Game
        </button>

        <div className="gamaMenuTop" onMouseLeave={() => setOpenVenue(false)}>
          <div className="gamaMenuTopItem" onMouseEnter={() => setOpenVenue(true)}>
            <button ref={refVenue} className="gamaMenuTop"
              onMouseDown={() => { setOpenVenue(!isOpenVenue) }}>
              Venue
              <IoMdArrowDropdown className="gamaMenuArrow" />
            </button>
          </div>
          <ControlledMenu anchorRef={refVenue} state={isOpenVenue ? 'open' : 'closed'} onClose={() => setOpenVenue(false)} transition={true}>
            <MenuItem value="Details" onClick={() => props.callback("venueDetails")}>
              Details
            </MenuItem>
            <MenuItem value="Food" onClick={() => props.callback("venueFood")}>
              Food
            </MenuItem>
            <MenuItem value="Beverages" onClick={() => props.callback("venueBeverages")}>
              Beverages
            </MenuItem>
          </ControlledMenu>
        </div>
      </div>
    </React.Fragment>
  );
}
