import React from 'react';

const eventCoViD = (props: any) =>
(
  <aside className="evenCoViD">
    <header className="evenCoViD-header">
    </header>
    <main className="evenCoViD-body">
      <h2>CoViD Mitigation Policy</h2>
      <p>
        The CoViD pandemic is still in effect as evidenced by a significant number
        of victims requiring hospitilization as well as fatalities on a daily basis.
        As an attendees of an event where we will be actively socializing in close
        proximity to each other, each of us should do our part to create a gaming
        space that is CoViD-cautious so that we do not inadvertently spread CoViD
        to our fellow gamers.
      </p>
      <p>
        With all of that in mind, Gamacea 2022's CoViD Mitigation Policy is
        designed to protect you from others just as much as it to protect others
        from you.  All attendees are required to abide by this policy while in
        attendance at Gamacea 2022.  Your purchase of a Gamacea 2022 registration
        is acknowledgement of this policy as well as the intimation to abide by
        the policy while in attendance at Gamacea 2022.
      </p>
      <p>
        Upon check-in, each person will need to:
      </p>
      <ol>
        <li>provide a government-issued photo ID</li>
        <li>
          CDC COVID-19 Vaccination Record Card with proof of completion of
          the full vaccination plus booster regimen as recommended by the
          CDC on a date that is two weeks or more prior to 9/30/2022
        </li>
        <li>sign a waiver</li>
        <li>
          wear masks at all times while in the convention event space
        </li>
      </ol>
    </main>
    <footer className="evenCoViD-footer">
    </footer>
  </aside>
);

export default eventCoViD;
