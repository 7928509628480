import "react-big-calendar/lib/css/react-big-calendar.css";
import "./GenericSessionCalendar.css";
import DateTimePicker from 'react-datetime-picker';
import React from 'react';
import { CalSession } from './GenericSessionCalendar';
import { useNavigate } from "react-router-dom";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import "./SessionCreator.css";
import moment from 'moment'



type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece]

export interface SessionCreationDetails
{
    start: Value,
    end: Value,
    name: string,
    bgg_title_link: string,
    info: string,
    room: string,
    table: number,
    slots: number,
    owner_player: boolean,
    owner: number,
    session_id: number
}

interface SessionCreatorProps
{
    userId: number,
    token: string,
    defaults?: CalSession,
    editSessionId?: number,
    evtId: number,
    setStart : Date,
    setStop : Date,
    highestTable: number,
    lowestTable: number,
    maxRSVP: number
}

const SessionCreator = (props: SessionCreatorProps) =>
{

    const [memoEditSessionId, setMemoEditSessionId] = React.useState<number | undefined>(undefined);
    const [startTime, setStartTime] = React.useState<Date>(props.setStart);
    const [stopTime, setStopTime] = React.useState<Date>(props.setStop);
    const [sessionName, setSessionName] = React.useState<string>("");
    const [bggPage, setBggPage] = React.useState<string>("");
    const [sessionDesc, setSessionDesc] = React.useState<string>("");
    const [roomDesc, setRoomDesc] = React.useState<string>("Ballroom");
    const [tableNum, setTableNum] = React.useState<string>("");
    const [slotMax, setSlotMax] = React.useState<string>("");
    const [autoRSVP, setAutoRSVP] = React.useState<boolean>(false);
    const [statusMsg, setStatusMsg] = React.useState<string>("");
    const [submitEnable, setSubmitEnable] = React.useState<boolean>(true);
    const [tableVerify, setTableVerify] = React.useState<string>("");
    const [startRedirect, setStartRedirect] = React.useState<boolean>(false);
    const [deleteRequested, setDeleteRequested] = React.useState<boolean>(false);

    const navigate = useNavigate()


    React.useEffect(() =>
    {
        props.defaults ? setStartTime(props.defaults.start) : setStartTime(props.setStart);
        props.defaults ? setStopTime(props.defaults.end) : setStopTime(props.setStop);
        setSessionName(props.defaults ? props.defaults.name : "")
        setBggPage((props.defaults && props.defaults.bgg_title_link) ? props.defaults.bgg_title_link : "")
        setSessionDesc(props.defaults ? props.defaults.info : "")
        setTableNum(props.defaults ? props.defaults.table.toString() : "")
        setSlotMax(props.defaults ? props.defaults.slots_available.toString() : "")
        setMemoEditSessionId(props.editSessionId);
    }, [props.defaults, props.editSessionId, props.setStart, props.setStop])


    const updateSession = () =>
    {
        const object =
        {
            start_time: startTime,
            end_time: stopTime,
            name: sessionName,
            bgg_title_link: bggPage,
            info: sessionDesc,
            room: roomDesc,
            table: parseInt(tableNum),
            slots: parseInt(slotMax),
            owner_player: autoRSVP,
            owner: props.userId,
            event_id: props.evtId,
            id: memoEditSessionId
        }
        console.log(object);

        const requestOptions =
        {
            body: JSON.stringify(object),
            method: 'POST',
            headers:
            {
                'Content-Type': `application/json`,
                "Access-Control-Allow-Origin": "*",
                'Authorization': `Bearer ${props.token}`
            }
        }
        return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/gsessions/editgsession', requestOptions)
            .then(res => res.json())
            .then((result) =>
            {
                if (result.status === 'success')
                {
                    setStatusMsg("Session Saved! Please Refresh Calendar.")
                    setSubmitEnable(false);
                }
                else
                {
                    console.log(result)
                    setStatusMsg("An Error has occured. " + result.reason);
                }
            })
    }


    const createSession = () =>
    {
        const start_time_local = moment.tz(startTime as Date,'America/New_York');
        const end_time_local = moment.tz(stopTime as Date,'America/New_York');
        const start_time = start_time_local.utc();
        console.log(start_time_local);
        console.log(start_time);


        const object =
        {
            start_time: start_time_local.utc(),
            end_time: end_time_local.utc(),
            name: sessionName,
            bgg_title_link: bggPage,
            info: sessionDesc,
            room: roomDesc,
            table: parseInt(tableNum),
            slots: parseInt(slotMax),
            owner_player: autoRSVP,
            owner: props.userId,
            event_id: props.evtId
        }
        console.log(object);
        
        
        const requestOptions =
        {
            body: JSON.stringify(object),
            method: 'POST',
            headers:
            {
                'Content-Type': `application/json`,
                "Access-Control-Allow-Origin": "*",
                'Authorization': `Bearer ${props.token}`
            }
        }
        return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/gsessions/creategsession', requestOptions)
            .then(res => res.json())
            .then((result) =>
            {
                if (result.status === 'success')
                {
                    setStatusMsg("Session Saved! Calendar is now refreshing...");
                    setSubmitEnable(false);
                    setStartRedirect(true);
                }
                else
                {
                    console.log(result)
                    setStatusMsg("An Error has occured. " + result.reason);
                }
            })

    }

    const deleteSession = () => {
        if (!memoEditSessionId) {
            return;
        }

        const requestOptions =
        {
            method: 'DELETE',
            headers:
            {
                'Authorization': `Bearer ${props.token}`
            }
        }

        return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/gsessions/deletegsession/' + memoEditSessionId, requestOptions).then(res => res.json())
            .then((result) => {
                if(result.status === 'success')
                {
                    setStatusMsg("Session Deleted. Calendar is now refreshing...");
                    setStartRedirect(true);

                }
                else
                {
                    console.log(result);
                    setStatusMsg("An Error occured when deleting the event. " + result.reason);
                }
            })
    }


    React.useEffect(() =>
    {
        if(startRedirect)
        {
            setTimeout(() =>
            {
                window.location.reload();
                setDeleteRequested(false);
            }, 3000)
        }
    }, [startRedirect])


    const starttimefcn = (val: Value) =>
    {
        console.log(val);
        console.log(val as Date);
        console.log(startTime);
        setStartTime(val as Date);
    }


    const submitEnabledandValidated = submitEnable && (tableNum !== "") && (tableVerify === "") 
        && (sessionName !== "") && (roomDesc !== "") && (slotMax !== "")


    console.log(startTime);
    console.log(stopTime);


    return (<div>
        <b>Creator View</b>
        {memoEditSessionId && <div><br></br> <b>Editing Selected Session!!</b></div>}
        <p>Session Name</p>
        <input name="SessionName"
            className="smallInput"
            placeholder="Game Name"
            value={sessionName}
            onChange={(val) => setSessionName(val.target.value)} />
        <p>BGG Page</p>
        <textarea name="BGGPage"
            className="smallTextField"
            value={bggPage}
            placeholder="https://www.boardgamegeek.com/....."
            onChange={(e) => setBggPage(e.target.value)} />
        <p>Description</p>
        <textarea name="Description"
            className="largeTextField"
            placeholder="What people need to know"
            value={sessionDesc}
            onChange={(e) => setSessionDesc(e.target.value)} />
        <p>Table</p>
        <input name="Table"
            className="smallInput"
            value={tableNum}
            pattern="[0-9]"
            onChange={(e) =>
            {
                setTableNum(e.target.value);
                const res = parseInt(e.target.value, 10);
                if (res < props.lowestTable || res > props.highestTable) {
                    setTableVerify(`Table must be between ${props.lowestTable} and ${props.highestTable}`);
                } else {
                    setTableNum(e.target.value);
                    setTableVerify("");
                }
            }} />
        <b>{tableVerify}</b>
        <p>Number of Slots</p>
        <input type="number"
                min="2"
                max={props.maxRSVP}
                name="Slots"
                className="smallInput"
                placeholder="Maximum number of participants"
                value={slotMax}
                onChange={(e) => setSlotMax(e.target.value)} />
        <p>Start Time</p>
        <DateTimePicker name="StartTime"
            value={startTime}
            onChange={(val) => starttimefcn(val)} />
        <p>Stop Time</p>
        <DateTimePicker name="StopTime"
            value={stopTime}
            onChange={(val) => setStopTime(val as Date)} />
        <br></br>
        <br></br>
        {!memoEditSessionId && <label>
            <input type="checkbox" name="AutoRSVP"
                checked={autoRSVP}
                onChange={() => setAutoRSVP(!autoRSVP)} /> Also sign yourself up for this session
        </label>}
        <br></br>
        <br></br>
        <div>
            {submitEnabledandValidated && !memoEditSessionId && <button
                className="buttonPrimary"
                onClick={() => createSession()}
            >Create Session</button>}
            {submitEnabledandValidated && memoEditSessionId && <button
                className="buttonPrimary"
                onClick={() => updateSession()}
            >Update Session</button>}
            {memoEditSessionId && <button className="buttonSecond" onClick={() => setDeleteRequested(true)}>Delete Session</button>}
            {deleteRequested && <div>
                <p>Are you sure you want to delete this session?</p>
                <button className="buttonPrimary" onClick={() => setDeleteRequested(false)}>Cancel Delete</button>
                <button className="buttonSecond" onClick={() => deleteSession()}>Confirm Delete</button>
                </div>}
            <br></br>
            <b>{statusMsg}</b>
        </div>
    </div>)
}

export default SessionCreator;