import VenueMap from '../pages/venues/components/VenueMap'
import { VenueDetails } from '../pages/venues/venueModels'
import CollapsableContent from './CollapsableContent'
import { UserDetails } from '../../models/user/userInfo'
import './CollapsableVenue.css'

interface CollapsableVenueProps {
    venue: VenueDetails
    isHidden: boolean
    title: string
    startingIsCollapsed: boolean
    registrantList ?: UserDetails[],
    user?: UserDetails
}

const CollapsableVenue = (props: CollapsableVenueProps) => {

    const isRegistered = props.registrantList && props.user && props.user.id && (props.registrantList.filter((usr) => props.user ? usr.id === props.user.id : false).length > 0)
    const isPrivate = props.venue.private

    const directionBlock =props.venue.detailed_directions && <div>
        <h4>Detailed Directions:</h4>
        <p>{props.venue.detailed_directions}</p> 
    </div>
    return(
        <div>
            <CollapsableContent title={props.title} startingIsCollapsed={props.startingIsCollapsed}>
                {(props.isHidden && !isRegistered) ? <div>
                    <p>The venue for this event is hidden until you have registered.</p>
                </div> :
                isPrivate ? <div>
                    <p>This venue is private. Please contact the owner for details.</p>
                </div> :
                <div>
                    <h3>{props.venue.name}</h3>
                    <h4>{props.venue.agg_address}</h4>
                        <VenueMap venue={props.venue} containerClassName='event-form-venue-map '/>
                    {directionBlock}
                </div> }
            </CollapsableContent>
        </div>
    )

}

export default CollapsableVenue;